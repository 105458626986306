import { Injectable } from '@angular/core';
import { ApplicationSettings, ApplicationSettingsService } from 'src/app/application-settings.service';
// import { Settings } from '../app/settings';
// import { SettingsService } from 'research.web.settings';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';

export interface UrlConfig {
  configurations: string;
  station: string;
  saveStation: string;
  saveStationComment: string;
  program: string;
  saveProgram: string;
  saveProgramComment: string;
  crops: string;
  // technologyTiers: string;
  // saveTechnologyTierTypes: string;
  lDAPUsers: string;
  users: string;
  exportCSVForStations: string;
  programDropDownLists: string;
  exportCSVForPrograms: string;
  evaluationZoneDropDownLists: string; 
  commercialMarketDropDownLists: string;
  regionsByCropID: string;
  programsByEvaluationZoneID: string; 
  evaluationZone: string;
  commercialMarketZones: string;
  breedingZone: string;
  saveEvaluationZone: string; 
  saveCommercialMarket: string;
  saveEvaluationZoneComment: string; 
  saveCommercialMarketComment: string;
  exportCSVForEvaluationZones: string; 
  exportCSVForCommercialMarketZones: string;
  generateResourceFile: string;
  stationCodes: string;
  regionsCombo: string;
  resourceFileDropDownLists: string;
  getSavedRules: string;
  region: string;
  saveRegion: string;
  saveRegionComment: string;
  regionDropDownLists: string;
  exportCSVForRegions: string;
  breedingZoneDropDownLists: string;
  saveBreedingZone: string;
  saveBreedingZoneComment: string;
  importResourceFile: string;
  saveResourceFileHistory: string;
  exportCSVForBreedingZones: string;
  getResourceFileHistory: string;
  getResourceFileScheduler: string;
  saveResourceFileScheduler: string;
  generateResourceFileFromHistory: string;
  generateResourceFileFromEmailLink: string;
  stationsByCropID: string;
  seedStorageLocationByCropID: string;
  evaluationByCropID: string; 
  getCategories: string;
  saveCategories: string;
  getAllocationEZProgramData: string;
  saveAllocationEZProgramData: string;
  saveAllocationEZPrograms: string;
  deleteAllocationEZProgramData: string;
  checkRADUser: string;
  saveRADUser: string;
  cleanCache: string;
  ssl: string;
  countries: string;
  getNextlevelAreaFromPRISM: string;
  saveSSL: string;
  exportCSVForSSL: string;
  saveSSLComment: string;
  getLEAndAOIReportData: string;
  saveLEDataToPrismStation: string;
  saveLEDataToPrismProgram: string;
  saveAOIDataToPrismEvaluationZone: string; 
  saveAOIDataToPrismCommercialMarket: string;
  saveAOIDataToPrismBreedingZone: string;
  saveAOIDataToPrismRegion: string;
  exportCSVForCategoryManager: string;
  exportCSVForAllocationEZProgram: string;
  stationDropDownLists: string;
  savePublishFile: string;
  resourceFileColumns: string;
  manualImportResourceFile: string;
  getRecentHistoryCount: string;
  getRecentHistory: string;
  deleteRecentComments: string;
  saveRule: string;
  getZabbixServer: string;
  deleteRules: string;
  getDropdownLists: string;
  getDemandCalendar: string;
  getCalendarCategories: string;
  saveDemandCalendar: string;
  uploadDemandCalendarData: string;
  saveCalendarCategory: string;
  deleteCalendarCategory: string;
  getMacroTemplates: string;
  saveMacroTemplate: string;
  getMacroTemplateData: string;
  exportDemandCalendar: string;
  deleteDemandCalendar: string;
  saveMacroTemplateData: string;
  getMacroTemplateDataAll: string;
  saveMacroTemplateDataAll: string;
  saveSnapshot: string;
  getSnapShot: string;
  exportCSVForMacroTemplateData: string;
  exportCSVForMacroTemplateHistoricalData: string;
  getREPSLOC_PlotType: string;
  getRepsLocFileData: string;
  exportCSVForRepsLocFileData: string;
  sSLType: string;
  getPrismStagesByCropName: string;
  SaveValidValueSets: string;
  getAPIVersion: string; 
  migrateCRAInfo: string;
  getCPFieldScientists: string;
  saveCPFieldScientists: string;
  getLookupTypes: string;
  saveLookupTypes: string; 
  exportCSVForCPFieldScientists: string; 
  saveCPFieldScientistComment: string; 
  getAllCountriesFomPRISM: string;
  gaaData: string;
  syncGAA: string;
  getClaims: string
}

interface EnvironmentConfig {
  name: string;
  environment: () => UrlConfig;
}

@Injectable({
  providedIn: 'root'
})

export class EnvironmentService {
  currentConfig: UrlConfig;
  settings:ApplicationSettings;
  public constructor(private _settingsService: ApplicationSettingsService) {
    this.settings = _settingsService.settings
    this.currentConfig = new Environment(this.settings);
  }
  public getEnvironment(): UrlConfig {
    
    
    return this.currentConfig;
  }
}

class Environment implements UrlConfig {
  public station = `${this.settings.apiUrl}/GetStations`;
  public exportCSVForStations = `${this.settings.apiUrl}/ExportCSVForStations`;
  public saveStation = `${this.settings.apiUrl}/SaveStation`;
  public saveStationComment = `${this.settings.apiUrl}/SaveStationComment`;
  public program = `${this.settings.apiUrl}/GetPrograms`;
  public saveProgram = `${this.settings.apiUrl}/SaveProgram`;
  public saveProgramComment = `${this.settings.apiUrl}/SaveProgramComment`;
  public crops = `${this.settings.apiUrl}/GetCrops`;
  public getAllCountriesFomPRISM = `${this.settings.apiUrl}/GetAllCountriesFomPRISM`;
  public lDAPUsers = `${this.settings.apiUrl}/GetLDAPUsers`;
  public users = `${this.settings.apiUrl}/GetUsers`;
  public stationCodes = `${this.settings.apiUrl}/GetStationCodes`;
  public programDropDownLists = `${this.settings.apiUrl}/BindProgramDropdownLists`;
  public regionsCombo = `${this.settings.apiUrl}/GetRegionsCombo`;
  public resourceFileDropDownLists = `${this.settings.apiUrl}/BindGenerateResourceFileDropdownLists`;
  public getSavedRules = `${this.settings.apiUrl}/GetSavedRules`;
  public region = `${this.settings.apiUrl}/GetRegions`;
  public saveRegion = `${this.settings.apiUrl}/SaveRegion`;
  public saveRegionComment = `${this.settings.apiUrl}/SaveRegionComment`;
  public regionDropDownLists = `${this.settings.apiUrl}/BindRegionDropdownLists`;
  public exportCSVForRegions = `${this.settings.apiUrl}/ExportCSVForRegions`;
  public saveRule = `${this.settings.apiUrl}/SaveRules`;
  public deleteRules = `${this.settings.apiUrl}/DeleteRules`;
  public generateResourceFile = `${this.settings.apiUrl}/GenerateResourceFile`;
  public exportCSVForPrograms = `${this.settings.apiUrl}/ExportCSVForPrograms`;
  public evaluationZoneDropDownLists = `${this.settings.apiUrl}/BindEvaluationZoneDropdownLists`; 
  public commercialMarketDropDownLists = `${this.settings.apiUrl}/BindCommercialMarketZoneDropdownLists`;
  public evaluationZone = `${this.settings.apiUrl}/GetEvaluationZones`; 
  public commercialMarketZones = `${this.settings.apiUrl}/GetCommercialMarketZones`;
  public breedingZone = `${this.settings.apiUrl}/GetBreedingZones`;
  public saveBreedingZone = `${this.settings.apiUrl}/SaveBreedingZone`;
  public saveBreedingZoneComment = `${this.settings.apiUrl}/SaveBreedingZoneComment`;
  public breedingZoneDropDownLists = `${this.settings.apiUrl}/BindBreedingZoneDropdownLists`;
  public exportCSVForBreedingZones = `${this.settings.apiUrl}/ExportCSVForBreedingZones`;
  public regionsByCropID = `${this.settings.apiUrl}/GetRegionsByCropID`;
  public stationsByCropID = `${this.settings.apiUrl}/GetStationsByCropID`;
  public seedStorageLocationByCropID = `${this.settings.apiUrl}/GetSeedStorageLocationByCropID`;
  public evaluationByCropID = `${this.settings.apiUrl}/GetEvaluationByCropID`; 
  public programsByEvaluationZoneID = `${this.settings.apiUrl}/GetProgramNamesByEvaluationZoneID`; 
  public saveEvaluationZone = `${this.settings.apiUrl}/SaveEvaluationZone`; 
  public saveCommercialMarket = `${this.settings.apiUrl}/SaveCommercialMarketZone`;
  public saveEvaluationZoneComment = `${this.settings.apiUrl}/SaveEvaluationZoneComment`; 
  public saveCommercialMarketComment = `${this.settings.apiUrl}/SaveCommercialMarketZoneComment`;
  public exportCSVForEvaluationZones = `${this.settings.apiUrl}/ExportCSVForEvaluationZones`; 
  public exportCSVForCommercialMarketZones = `${this.settings.apiUrl}/ExportCSVForCommercialMarketZones`;
  public importResourceFile = `${this.settings.apiUrl}/ImportResourceFile`;
  public saveResourceFileHistory = `${this.settings.apiUrl}/SaveResourceFileHistory`;
  public configurations = `${this.settings.apiUrl}/api/configuration`;
  public getResourceFileHistory = `${this.settings.apiUrl}/GetResourceFileHistory`;
  public getResourceFileScheduler = `${this.settings.apiUrl}/GetResourceFileScheduler`;
  public saveResourceFileScheduler = `${this.settings.apiUrl}/SaveResourceFileScheduler`;
  public generateResourceFileFromHistory = `${this.settings.apiUrl}/GenerateResourceFileFromHistory`;
  public generateResourceFileFromEmailLink = `${this.settings.apiUrl}/GenerateResourceFileFromEmailLink`;
  public getCategories = `${this.settings.apiUrl}/GetCategories`;
  public saveCategories = `${this.settings.apiUrl}/SaveCategories`;
  public getAllocationEZProgramData = `${this.settings.apiUrl}/GetAllocationEZProgramData`;
  public saveAllocationEZProgramData = `${this.settings.apiUrl}/SaveAllocationEZProgramData`;
  public saveAllocationEZPrograms = `${this.settings.apiUrl}/SaveAllocationEZPrograms`;
  public deleteAllocationEZProgramData = `${this.settings.apiUrl}/DeleteAllocationEZProgramData`;
  public checkRADUser = `${this.settings.apiUrl}/CheckRADUser`;
  public saveRADUser = `${this.settings.apiUrl}/SaveRADUser`;
  public cleanCache = `${this.settings.apiUrl}/CleanCache`;
  public ssl = `${this.settings.apiUrl}/GetSeedStorageLocations`;
  public countries = `${this.settings.apiUrl}/BindSSLDropdownLists`;
  public getNextlevelAreaFromPRISM = `${this.settings.apiUrl}/GetNextlevelAreaFromPRISM`;
  public saveSSL = `${this.settings.apiUrl}/SaveSeedStorageLocation`;
  public exportCSVForSSL = `${this.settings.apiUrl}/ExportCSVForSeedStorageLocations`;
  public saveSSLComment = `${this.settings.apiUrl}/SaveSeedStorageLocationComment`;
  public getLEAndAOIReportData = `${this.settings.apiUrl}/GetLEAndAOIReportData`;
  public saveLEDataToPrismStation = `${this.settings.apiUrl}/SaveLEDataToPrismStation`;
  public saveLEDataToPrismProgram = `${this.settings.apiUrl}/SaveLEDataToPrismProgram`;
  public saveAOIDataToPrismEvaluationZone = `${this.settings.apiUrl}/SaveAOIDataToPrismEvaluationZone`; 
  public saveAOIDataToPrismCommercialMarket = `${this.settings.apiUrl}/SaveAOIDataToPrismCommercialMarketZone`;
  public saveAOIDataToPrismBreedingZone = `${this.settings.apiUrl}/SaveAOIDataToPrismBreedingZone`;
  public saveAOIDataToPrismRegion = `${this.settings.apiUrl}/SaveAOIDataToPrismRegion`;
  public exportCSVForCategoryManager = `${this.settings.apiUrl}/ExportCSVForCategoryManager`;
  public exportCSVForAllocationEZProgram = `${this.settings.apiUrl}/ExportCSVForAllocationEZProgram`;
  public stationDropDownLists = `${this.settings.apiUrl}/BindStationDropdownLists`;
  public savePublishFile = `${this.settings.apiUrl}/SavePublishFile`;
  public resourceFileColumns = `${this.settings.apiUrl}/GetResourceFileColumns`;
  public manualImportResourceFile = `${this.settings.apiUrl}/ManualImportResourceFile`;
  public getRecentHistoryCount = `${this.settings.apiUrl}/GetRecentHistoryCount`;
  public getRecentHistory = `${this.settings.apiUrl}/GetRecentHistory`;
  public deleteRecentComments = `${this.settings.apiUrl}/DeleteRecentComments`;
  public getZabbixServer = `${this.settings.apiUrl}/GetZabbixServer`;
  public getDropdownLists = `${this.settings.apiUrl}/GetDropdownLists`;
  public getDemandCalendar = `${this.settings.apiUrl}/GetDemandCalendar`;
  public getCalendarCategories = `${this.settings.apiUrl}/GetCalendarCategories`;
  public saveCalendarCategory = `${this.settings.apiUrl}/SaveCalendarCategory`;
  public deleteCalendarCategory = `${this.settings.apiUrl}/DeleteCalendarCategory`;
  public getMacroTemplates = `${this.settings.apiUrl}/GetMacroTemplates`;
  public saveMacroTemplate = `${this.settings.apiUrl}/SaveMacroTemplate`;
  public saveDemandCalendar = `${this.settings.apiUrl}/SaveDemandCalendar`;
  public uploadDemandCalendarData = `${this.settings.apiUrl}/UploadDemandCalendarData`;
  public getMacroTemplateData = `${this.settings.apiUrl}/GetMacroTemplateData`;
  public exportDemandCalendar = `${this.settings.apiUrl}/ExportDemandCalendar`;
  public deleteDemandCalendar = `${this.settings.apiUrl}/DeleteDemandCalendar`;
  public saveMacroTemplateData = `${this.settings.apiUrl}/SaveMacroTemplateData`;
  public getMacroTemplateDataAll = `${this.settings.apiUrl}/GetMacroTemplateDataAll`;
  public saveMacroTemplateDataAll = `${this.settings.apiUrl}/SaveMacroTemplateDataAll`;
  public saveSnapshot = `${this.settings.apiUrl}/SaveSnapshot`;
  public getSnapShot = `${this.settings.apiUrl}/GetSnapshot`;
  public exportCSVForMacroTemplateData = `${this.settings.apiUrl}/ExportCSVForMacroTemplateData`;
  public exportCSVForMacroTemplateHistoricalData = `${this.settings.apiUrl}/ExportCSVForMacroTemplateHistoricalData`;
  public sSLType = `${this.settings.apiUrl}/GetSSLTypes`;
  public getREPSLOC_PlotType = `${this.settings.apiUrl}/GetREPSLOC_PlotType`;
  public getRepsLocFileData = `${this.settings.apiUrl}/GetRepsLocFileData`;
  public exportCSVForRepsLocFileData = `${this.settings.apiUrl}/ExportCSVForRepsLocFileData`;
  public getPrismStagesByCropName = `${this.settings.apiUrl}/GetPRISMStages`;
  public SaveValidValueSets = `${this.settings.apiUrl}/SaveValidValueSets`;
  public getAPIVersion = `${this.settings.apiUrl}/GetAPIVersion`; 
  public migrateCRAInfo = `${this.settings.apiUrl}/MigrateCRAInfo`;
  public getCPFieldScientists = `${this.settings.apiUrl}/GetCPFieldScientists`;
  public saveCPFieldScientists = `${this.settings.apiUrl}/SaveCPFieldScientists`;
  public getLookupTypes = `${this.settings.apiUrl}/GetLookupTypes`;
  public saveLookupTypes = `${this.settings.apiUrl}/SaveLookupTypes`; 
  public exportCSVForCPFieldScientists = `${this.settings.apiUrl}/ExportCSVForCPFieldScientists`; 
  public saveCPFieldScientistComment = `${this.settings.apiUrl}/SaveCPFieldScientistComment`;
  public gaaData = `${this.settings.apiUrl}/GetGAAData`;
  public syncGAA = `${this.settings.apiUrl}/SyncGAA`; 
  public getClaims = `${this.settings.apiUrl}/GetClaims`;
  public constructor(private settings: ApplicationSettings) { }
}