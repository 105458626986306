<form #frm="ngForm">
  <div id="dvCreateFile">
    <p-panel>
      <p-header>
        <div class="grid">
          <div class="col-8">
            <b>Create Resource File</b>
          </div>
          <div class="col-4">
            <div class="grid">
              <div class="col-4">
                <button id="btnCreateFileAddRule" type="button" pButton icon="fa fa-plus-circle" class="p-button-primary"
                  label="Add Rule" title="Add New Rule" (click)="addNewRule(dt)" style="float: right; width:150px"></button>
              </div>
              <div class="col-4">
                  <button id="btnCreateFileSave" type="button" pButton icon="fa fa-file-o" class="p-button-success" [disabled]="ruleResult.length === 0"
                    (click)="SaveRules()" iconPos="left" label="Save Rules" title="Save Rules" style="float: right; width:150px"></button>
              </div>
              <div class="col-4">
                  <button id="btnCreateFileReset" type="button" pButton icon="fa fa-refresh" class="p-button-warning"
                    (click)="Reset()" iconPos="left" label="Reset" title="Set To Default" style="float: right; width:150px"></button>
              </div>
            </div>
          </div>
        </div>
      </p-header>

      <p-table #dt id="dtrules" [value]="ruleResult" dataKey="Title" [columns]="cols" [scrollable]="true" (onEditInit)="onEditInit($event)"
        [(selection)]="selectedRow" selectionMode="single" [loading]="loading" loadingIcon="fa fa-spinner" [scrollable]="true"
        [style]="{'height': scrollHeight, 'overflow': 'auto'}" (onFilter)="filterCreateFileTable($event)">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th>Title</th>
            <th>Crop</th>
            <th>RCZ</th>
            <th>Evaluation Zone</th>
            <th>Program</th>
            <th>R&D Center</th>
            <th>Country</th>
            <th style="width: 3em"></th>
            <th style="width: 3em"></th>
            <th style="width: 3em"></th>
          </tr>

        </ng-template>
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-rowNode let-expanded="expanded" let-columns="columns">
          <tr [pSelectableRow]="rowData" class="ui-widget-header" *ngIf="!(rowGroupMetadata && rowGroupMetadata[rowData.Title]) ? true: rowGroupMetadata[rowData.Title].index === rowIndex ">
            <td [pEditableColumn]="rowData" colspan="9">
              <div>
                <a href="#" [pRowToggler]="rowData">
                  <i [ngClass]="expanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"></i>
                </a>
                <button id="btnCreateFileTitle" (click)="editTitle(rowData, rowIndex, false)" style="border:none;background-color: inherit; color: black;">{{rowData.Title}}</button>
              </div>
            </td>
            <td style="width: 3em">
              <button id="btnCreateFileCopy" type="button" pButton icon="fa fa-copy" title='Copy Rule' (click)="copy(rowData)" class="p-button-success"></button>
            </td>
            <td style="width: 3em">
              <button id="btnCreateFileAddSubRule" type="button" pButton icon="fa fa-plus-circle" title="Add Rule" class="p-button-primary" (click)="expanded ? Add(rowData, rowIndex) : warningToExpand()"
                iconPos="left"></button>
            </td>
            <td style="width: 3em">
              <button id="btnCreateFileRemoveRule" type="button" pButton icon="fa fa-minus-circle" (click)="removeAll(rowData)" title="Remove Rule" class="p-button-danger"
               ></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-rowIndex="rowIndex" let-col let-rowData>
          <tr>
            <td></td>
            <td [pEditableColumn]="rowData" [pEditableColumnField]="'Crops'">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-multiSelect #ms id="msCreateFileCrops" filterPlaceHolder="Select Crops" #dd1 name="crops" [options]="stationCropName" [(ngModel)]="rowData.Crops"
                    appendTo="body" panelStyleClass="long-width" (onChange)="onMSChange(rowData, rowIndex, dt)" (onPanelHide)="onMSPanelHide()" selectedItemsLabel="{0} items selected"></p-multiSelect>
                </ng-template>
                <ng-template pTemplate="output">
                  <p style="width:200px; word-wrap: break-word">{{[rowData.CropNames]}}</p>
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="rowData" [pEditableColumnField]="'Regions'">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-multiSelect #ms id="msCreateFileRegions" [placeholder]="'Choose'" filterPlaceHolder="Select RCZs" [resetFilterOnHide]="true" name="region" [options]="selectRegions" [(ngModel)]="rowData.Regions"
                    appendTo="body" (onChange)="onMSChange(rowData, rowIndex, dt)" (onPanelHide)="onMSPanelHide()" panelStyleClass="long-width"  selectedItemsLabel="{0} items selected"></p-multiSelect>
                </ng-template>
                <ng-template pTemplate="output">
                  <p class="pTemplate-Output">{{[rowData.RegionNames]}}</p>
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="rowData" [pEditableColumnField]="'EvaluationZones'">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-multiSelect #ms id="msCreateFileEZs" filterPlaceHolder="Select Eval Zones" [placeholder]="'Choose'" [resetFilterOnHide]="true" name="evalZone" [options]="selectedEvaluations"
                    [(ngModel)]="rowData.EvaluationZones" appendTo="body" (onChange)="onMSChange(rowData, rowIndex, dt)" (onPanelHide)="onMSPanelHide()"
                    panelStyleClass="long-width" selectedItemsLabel="{0} items selected"></p-multiSelect>
                </ng-template>
                <ng-template pTemplate="output">
                  <p class="pTemplate-Output">{{[rowData.EvaluationZoneNames]}}</p>
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="rowData" [pEditableColumnField]="'Programs'">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-multiSelect #ms id="msCreateFilePrograms"  filterPlaceHolder="Select Programs" [placeholder]="'Choose'" filterPlaceHolder="Select Programs" [resetFilterOnHide]="true" name="programs" [options]="selectedPrograms"
                    [(ngModel)]="rowData.Programs" appendTo="body" (onChange)="onMSChange(rowData, rowIndex, dt)"  selectedItemsLabel="{0} items selected" (onPanelHide)="onMSPanelHide()" panelStyleClass="long-width"
                    [style]="{'width':'100%'}"></p-multiSelect>
                </ng-template>
                <ng-template pTemplate="output">
                  <p class="pTemplate-Output" style="word-wrap: break-word">{{[rowData.ProgramNames]}}</p>
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="rowData" [pEditableColumnField]="'Stations'">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-multiSelect #ms id="msCreateFileStations" filterPlaceHolder="Select Stations" [placeholder]="'Choose'" filterPlaceHolder="Select Stations"  [resetFilterOnHide]="true" name="stations" [options]="selectedStations"
                    [(ngModel)]="rowData.Stations" appendTo="body" (onChange)="onMSChange(rowData, rowIndex, dt)"  selectedItemsLabel="{0} items selected" (onPanelHide)="onMSPanelHide()" panelStyleClass="long-width"
                    [style]="{'width':'100%'}"></p-multiSelect>
                </ng-template>
                <ng-template pTemplate="output">
                  <p class="pTemplate-Output" style="word-wrap: break-word">{{[rowData.StationNames]}}</p>
                </ng-template>
              </p-cellEditor>
            </td>
            <td [pEditableColumn]="rowData" [pEditableColumnField]="'Countries'">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-multiSelect #ms id="msCreateFileCountries" filterPlaceHolder="Select Countries" [placeholder]="'Choose'" filterPlaceHolder="Select Countries"  [resetFilterOnHide]="true" name="countries" [options]="selectedCountries"
                    [(ngModel)]="rowData.Countries" appendTo="body" (onChange)="onMSChange(rowData, rowIndex, dt)" selectedItemsLabel="{0} items selected" (onPanelHide)="onMSPanelHide()"
                    panelStyleClass="long-width" [style]="{'width':'100%'}"></p-multiSelect>
                </ng-template>
                <ng-template pTemplate="output">
                  <p class="pTemplate-Output" style="word-wrap: break-word">{{rowData.CountryNames}}</p>
                </ng-template>
              </p-cellEditor>
            </td>
            <td colspan='3' style="width: 9em">
              <button id="btnCreateFileDelete" type="button" pButton icon="fa fa-remove" (click)="remove(rowIndex)" class="p-button-danger" title="Delete Row"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <p-footer>
        <div class="grid">
          <div class="col-10">
          </div>
          <div class="col-2">
            <button id="btnCreateFileGenerateFile" type="button" pButton icon="fa fa-download" class="p-button-success" [disabled]="!selectedRow" selectedItemsLabel="{0} items selected" (click)="GenerateResourceFile()"
              iconPos="left" label="Create File" style="float:right"></button>
          </div>
        </div>
      </p-footer>
    </p-panel>
  </div>
  <input type="hidden" id="txtCreateFileTitleIndex">
  <input type="hidden" id="txtCreateFileTitleData">
</form>
<p-dialog header="Add / Update Title" [style]="{'width': '500px', 'height': '150px'}" [(visible)]="displayTitle" showEffect="fade"
  [closeOnEscape]="true" [closable]="true" [modal]="true" (onShow)="onTitleDialogShow()">
  <div class="grid">
    <div class="col-2 controlLabel">
      <label for="txtCreateFileTitle">Title: </label>
    </div>
    <div class="col-6">
      <input type="text" id="txtCreateFileTitle" [(ngModel)]="createFileTitle" required maxlength="100" pInputText style="width: 100%" (keyup)="validateTitle()">
      <input type="hidden" id="txtCreateFileTitleOriginal">
    </div>
    <div class="col-4">
      <button id="btnCreateFileSetTitle" type="button" pButton icon="fa fa-save" class="p-button-success" selectedItemsLabel="{0} items selected" [disabled]="disableTitleButton" (click)="setTitle()"
        label="Set Title" title="Set Title"></button>
    </div>
  </div>
</p-dialog>
