import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule } from "@angular/forms";
import { CommonService } from '../shared/common.service';
import { SharedService } from './../services/shared.service';
import { UserClaim } from '../shared/userClaim';
import { Constants, CropModel, Program, StationLite } from '../shared';
import { RadUser } from '../shared/radUser';
import { MultiSelectModule } from 'primeng/multiselect';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete'
import { PanelModule } from 'primeng/panel';
import { ProgramService } from '../program/program.service';
import { CreateUserAssociationService } from './create-user-association-service';

@Component({
  selector: 'app-create-user-association',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    PanelModule,
    MultiSelectModule,
    AutoCompleteModule
  ],
  templateUrl: './create-user-association.component.html',
  styleUrl: './create-user-association.component.scss'
})

export class CreateUserAssociationComponent implements OnInit {
  public userName: string;
  public isROVAdmin = false;
  public isROVUser = false;
  public isROVSuperUser = false;
  public loading = false;
  public loadingUsers = false;

  allUsers: RadUser[] = [];
  public users: RadUser[] = [];
  allCrops: CropModel[] = [];
  public crops: CropModel[] = [];
  public stations: StationLite[] = [];
  public programs: Program[] = [];

  public userAssocForm = this.fb.nonNullable.group({
    selectedUsers: new FormControl(),
    selectedCrops: new FormControl(),
    selectedStations: new FormControl(),
    selectedPrograms: new FormControl()
  })

  constructor(private fb: FormBuilder, private _commonService: CommonService,
      private _sharedService: SharedService, private _programService: ProgramService,
      private _userAssocService: CreateUserAssociationService ) {
  }

  ngOnInit(): void {
    this.getLoginInfo();
    this.loadFormData();
  }

  filterCrops(event: AutoCompleteCompleteEvent) {
    this.crops = this.findFilteredItems(this.allCrops, event.query);
  }

  filterUsers(event: AutoCompleteCompleteEvent) {
    this.users = this.findFilteredItems(this.allUsers, event.query);
  }

  findFilteredItems(allItems: any[], query: string): any[] {
    let filtered: any[] = [];
    
    for (let i = 0; i < allItems.length; i++) {
        let item = allItems[i];
        if (item.label.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(item);
        }
    }

    return filtered;
  }

  getLoginInfo() {
    let localRoles = this._sharedService.getSessionStorageValue(Constants.UIUSERROLE);
    if (localRoles !== '' && localRoles !== null) {
      const userClaim = JSON.parse(localRoles || '{}') as UserClaim;
      this.userName = userClaim.username;
      this.isROVAdmin = userClaim.isROVAdmin;
      this.isROVUser = userClaim.isROVUser;
    }
  }

  loadFormData() {
    this.loadUsersList();
    this.loadCropStationProgramLists();
  }

  loadCropStationProgramLists() {
    this.loading = true;

    this._programService.bindProgramDropdownLists().subscribe({
      next: data => {
        if (data.ProgramCodes && data.ProgramCodes.length > 0) {
          this.programs = data.ProgramCodes;
          this.programs.forEach(elmt => {
            elmt.value = elmt.ProgramID;
            elmt.label = elmt.ProgramCode + ' - ' + elmt.ProgramName;
          });
        }

        if (data.Crops && data.Crops.length > 0) {
          this.allCrops = data.Crops;
          this.allCrops.forEach(elmt => {
            elmt.value = elmt.CropID;
            elmt.label = elmt.CropDescription;
          });   
        }

        if (data.Stations && data.Stations.length > 0) {
          this.stations = data.Stations;
          this.stations.forEach(elmt => {
            elmt.value = elmt.StationID;
            elmt.label = elmt.StationCode + ' - ' + elmt.StationName;
          });        
        }
      },
      error: error => {
        if (error === '') {
          this.displayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.displayErrorMessage('Error', error);
        }
        this.loading = false;
      },
      complete: () => { this.loading = false; }
   });
  }

  loadUsersList() {
    this.loadingUsers = true;

    this._userAssocService.loadUsers().subscribe({
      next: data => {
        if (data && data.length > 0) {
          this.allUsers = data;
          this.allUsers.forEach(elmt => {
            elmt.value = elmt.Id;
            elmt.label = elmt.DisplayName;
          });   
        }
      },
      error: error => {
        if (error === '') {
          this.displayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.displayErrorMessage('Error', error);
        }

        this.loadingUsers = false;
      },
      complete: () => { this.loadingUsers = false; }
    });
  }

  displayErrorMessage(title: string, message: string) {
    this._commonService.DisplayErrorMessage(title, message);
  }

  displayWarningMessage(title: string, message: string) {
    this._commonService.DisplayWarningMessage(title, message);
  }

  displayInfoMessage(title: string, message: string) {
    this._commonService.DisplayInfoMessage(title, message);
  }

  displaySuccessMessage(title: string, message: string) {
    this._commonService.DisplaySuccessMessage(title, message);
  }
}