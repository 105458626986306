import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewEncapsulation} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import {
  ApprovalComments,
  BreedingZoneCombo,
  Constants,
  CropModel,
  ExperimentProjectCodeResult,
  LocationProjectCodeResult,
  Person,
  Program,
  ProgramForm,
  ProgramPurpose,
  ProgramPurposeModel,
  ProgramResultModel,
  ProgramType,
  ProgramTypeModel,
  ProjectLEResult,
  RequestStatus,
  SeedEvaluationZoneCombo,
  SeedEvaluationZoneComboModel,
  SeedStorageLocationCombo,
  SeedStorageLocationComboModel,
  SSLLEResult,
  Station,
  StationLEResult,
  StationResultModel,
  UserRequestedData
} from "../shared";
import {UserClaim} from "../shared/userClaim";

import { ConfirmationService, Message, SelectItem } from "primeng/api";
import { AppService } from "../app.service";
import { CommonService } from "../shared/common.service";
import { ProgramService } from "./program.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Dropdown } from "primeng/dropdown";
import { LERequestedData } from "../shared/lERequstedData";
import { BreedingZoneResultModel } from "../shared/breedingZoneResultModel";
import { LookupType } from "../shared";
import { SharedService } from './../services/shared.service';

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ProgramComponent implements OnChanges {
  msgs: Message[] = [];
  status: SelectItem[] = [];
  CropName: string;
  selectedItems: any[];
  cols: any[];
  dialogVisible: boolean;
  dataloaded: boolean;
  displayInvestigator: boolean;
  displayComments: boolean;
  programGridHeight: string;
  selectedStation = '';
  expandedItems: any[] = [];
  selectedStatus: string;
  sourceIdentifier: string;
  sourceComponent: string;

  public disableCommentButton = false;
  public programLead: SelectItem[] = [];
  public includeProgramSize: boolean = false;
  public existingPeople: Person[] = [];

  public existingPeopleString = '';
  public userName: string;
  public isROVAdmin = false;
  public isROVUser = true;
  public programAllData: ProgramResultModel[] = [];
  public filteredData: ProgramResultModel[] = [];
  public programCodes: Program[] = [];
  public programCropName: CropModel[] = [];
  public programStationName: Station[] = [];
  public shipToStationName: Station[] = [];
  public allStationName: Station[] = [];
  public allShipToStationName: Station[] = [];
  public programTypeName: ProgramType[] = [];
  public seedStorageLocationName: SeedStorageLocationCombo[] = [];
  public allSeedStorageLocationName: SeedStorageLocationCombo[] = [];
  public seedEvaluationZoneName: SeedEvaluationZoneCombo[] = [];
  public evalutionZones: SeedEvaluationZoneCombo[] = [];
  public seedBreedingZoneName: BreedingZoneCombo[] = [];
  public breedingZones: BreedingZoneCombo[] = [];
  public programPurposeName: ProgramPurpose[] = [];
  public approvalCommentData: any[] = [];
  public programData: ProgramResultModel = new ProgramResultModel();
  public _approvalComments: ApprovalComments = new ApprovalComments();
  public parentComponent = 'Program';
  loading: boolean;
  public distinctCodes: SelectItem[];
  public sortedCodes: SelectItem[];
  public sortedStations: SelectItem[];
  public sortedRegions: SelectItem[];
  public sortedEZones: SelectItem[];
  public distinctStations: SelectItem[];
  public distinctRegions: SelectItem[];
  public distinctEZones: SelectItem[];
  public Height = '200';

  public saveLabelText = 'Save';
  public saveIcon = 'fa fa-save';
  public saveIconClass = 'p-button-success';
  public filteredProgramName: string;
  public filteredProgramCode: string;
  public filteredProgramCrop: string;
  public filteredStationCode: string;
  public filteredRegion: string;
  public filteredProgramPrincipalInvestigatorNames: string;
  public filteredPRequestStatus: string;
  public filteredEZ: string;
  public displayLEIDWindow = false;
  public existingLEIDString = '';
  public leIDs: number[] = [];
  public projectLEResult: ProjectLEResult[] = [];
  public stationLEResult: StationLEResult[] = [];
  public sSLLEResult: SSLLEResult[] = [];
  public displayResponsibleSubfunctionDialog: boolean;
  public PrimaryResponsibleSubFunction: string = Constants.LOOKUPTYPERESPONSIBLESUBFUNCTION;
  public locationProjectCodeResult: LocationProjectCodeResult[] = [];
  public experimentProjectCodeResult: ExperimentProjectCodeResult[] = [];
  public panelHeight = '350px';
  public resizeIcon = 'fa fa-expand';
  public height = 580;
  public width = 1200;
  public positionLeft = '50px';
  public positionTop = '40px';
  public top = 0;
  public left = 0;
  selectedBZ: string[] = [];
  ProgramID: string;
  expandedRows: { [id: string]: boolean } = {};
  first: number = 0;
  responsiblesubfunctions: LookupType[];
  public programFormGroup: FormGroup;
  public programForm = new ProgramForm(
    '',
    '',
    '',
    '',
    0,
    '',
    '',
    '',
    '',
    new ProgramTypeModel(),
    '',
    [],
    '',
    new StationResultModel(),
    0,
    0,
    '',
    '',
    new SeedEvaluationZoneComboModel(),
    '',
    '',
    '',
    new StationResultModel(),
    '',
    new SeedStorageLocationComboModel(),
    '',
    new ProgramPurposeModel(),
    '',
    [],
    new Person(),
    '',
    new Date(),
    '',
    new Person(),
    new Date(),
    '',
    new Person(),
    new Date(),
    '',
    new Person(),
    new Date(),
    '',
    [],
    new LookupType(),
    '',
  );

  @Input()
  public selectedTabIndex = 0;

  @Input()
  public selectedRequestID: string;

  @Input()
  public cleanCache: boolean;

  @Output()
  public cleanUpCache: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private route: ActivatedRoute, private _appService: AppService,
    private _programService: ProgramService, private _commonService: CommonService,
    private _confirmation: ConfirmationService, private router: Router,
    private _fb: FormBuilder, private sharedservice: SharedService ) {
  }

  ConvertDateToString(dt: Date): string {
    return this._commonService.ConvertDateToString(dt);
  }

  buildForm(): void {
    if (this.isROVAdmin) {
      this.programFormGroup = this._fb.group({
        'ProgramName': [this.programForm.ProgramName, Validators.required],
        'ProgramCode': [this.programForm.ProgramCode, Validators.required],
        'ProgramPrincipalInvestigatorNames': [this.programForm.ProgramPrincipalInvestigatorNames],
        'Description': [this.programForm.Description],
        'ProgramTypeID': [this.programForm.ProgramTypeID, this.programForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'ProgramSize': [this.programForm.ProgramSize, this.programForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'CropID': [this.programForm.CropID, Validators.required],
        'StationID': [this.programForm.StationID, Validators.required],
        'ShipToStationID': [this.programForm.ShipToStationID],
        'SeedStorageLocationID': [this.programForm.SeedStorageLocationID],
        'EvaluationZoneID': [this.programForm.EvaluationZoneID, this.programForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'ProgramPurposeID': [this.programForm.ProgramPurposeID, this.programForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'CRMLower': [this.programForm.CRMLower, this.programForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'CRMUpper': [this.programForm.CRMUpper, this.programForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'RequestStatus': [this.programForm.RequestStatus],
        'LEID': [this.programForm.LEID, this.programForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'Comment': [this.programForm.ApprovalComments],
        'ProgramBreedingZoneAssoc': [this.selectedBZ],
        'PrimaryResponsibleSubFunctionId':[this.programForm.PrimaryResponsibleSubFunctionId]

      });
    } else {
      this.programFormGroup = this._fb.group({
        'ProgramName': [this.programForm.ProgramName, Validators.required],
        'ProgramCode': [this.programForm.ProgramCode, Validators.required],
        'ProgramPrincipalInvestigatorNames': [this.programForm.ProgramPrincipalInvestigatorNames],
        'Description': [this.programForm.Description],
        'ProgramTypeID': [this.programForm.ProgramTypeID, this.programForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'ProgramSize': [this.programForm.ProgramSize,this.programForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'CropID': [this.programForm.CropID, Validators.required],
        'StationID': [this.programForm.StationID, Validators.required],
        'ShipToStationID': [this.programForm.ShipToStationID],
        'SeedStorageLocationID': [this.programForm.SeedStorageLocationID],
        'EvaluationZoneID': [this.programForm.EvaluationZoneID, this.programForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'ProgramPurposeID': [this.programForm.ProgramPurposeID, this.programForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'CRMLower': [this.programForm.CRMLower, this.programForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'CRMUpper': [this.programForm.CRMUpper, this.programForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'RequestStatus': [this.programForm.RequestStatus],
        'LEID': [this.programForm.LEID, this.programForm.RequestStatus == RequestStatus.ACTIVE? Validators.required:""],
        'Comment': [this.programForm.ApprovalComments],
        'ProgramBreedingZoneAssoc': [this.selectedBZ],
        'PrimaryResponsibleSubFunctionId':[this.programForm.PrimaryResponsibleSubFunctionId]
      });
    }
  }

  public getResponsibleSubfunctionLookUp() {
    this._commonService.getLookupTypes(Constants.LOOKUPTYPERESPONSIBLESUBFUNCTION, true)
      .subscribe({
        next: (response) => {
          this.responsiblesubfunctions = response;

          this.responsiblesubfunctions.forEach(element => {
            element.value = element.Id;
            element.label = element.Name;
          });
        },
        error: (error) => { }
      });
  }

  public getLookupTypes() {
    this._commonService.getLookupTypes(Constants.LOOKUPTYPERESPONSIBLESUBFUNCTION, true)
      .subscribe({
        next: (response) => {
          this.responsiblesubfunctions = response;

          this.responsiblesubfunctions.forEach(element => {
            element.value = element.Id;
            element.label = element.Name;
          });
        },
        error: (error) => { }
      });
  }

  showResponsibleSubfunctionDialog() {
    this.displayResponsibleSubfunctionDialog = true;
  }

  bindProgramDropdownLists() {
    this._programService.bindProgramDropdownLists().subscribe({
      next: data => {
        if (data.ProgramCodes && data.ProgramCodes.length > 0) {
          this.programCodes = data.ProgramCodes;
        }

        if (data.Crops && data.Crops.length > 0) {
          this.programCropName = data.Crops;
          this.programCropName.forEach(elmt => {
            elmt.value = elmt.CropID;
            elmt.label = elmt.CropDescription;
          });
        }

        if (data.ProgramTypes && data.ProgramTypes.length > 0) {
          this.programTypeName = data.ProgramTypes;
          this.programTypeName.forEach(elmt => {
            elmt.value = elmt.Id;
            elmt.label = elmt.Name;
          });
        }
        if (data.Stations && data.Stations.length > 0) {
          this.programStationName = data.Stations;
          this.programStationName.forEach(elmt => {
            elmt.value = elmt.StationID;
            elmt.label = elmt.StationCode + ' - ' + elmt.StationName;
          });
          this.shipToStationName = data.Stations;
          this.shipToStationName.forEach(elmt => {
            elmt.value = elmt.StationID;
            elmt.label = elmt.StationCode + ' - ' + elmt.StationName;
          });
        }

        if (data.SeedStorageLocations && data.SeedStorageLocations.length > 0) {
          this.seedStorageLocationName = data.SeedStorageLocations;
          this.seedStorageLocationName.forEach(elmt => {
            elmt.value = elmt.Id;
            elmt.label = elmt.StationName + ' - ' + elmt.Name;
          });
        }

        if (data.EvalutionZones && data.EvalutionZones.length > 0) {
          this.seedEvaluationZoneName = data.EvalutionZones;
          this.seedEvaluationZoneName.forEach(elmt => {
            elmt.value = elmt.Id;
            elmt.label = elmt.Code + ' - ' + elmt.Name;
          });
        }

        if (data.ProgramPurposes && data.ProgramPurposes.length > 0) {
          this.programPurposeName = data.ProgramPurposes;
          this.programPurposeName.forEach(elmt => {
            elmt.value = elmt.Id;
            elmt.label = elmt.Code + ' - ' + elmt.Name;
          });
        }

        if (data.BreedingZoneCodes && data.BreedingZoneCodes.length > 0) {
          this.seedBreedingZoneName = data.BreedingZoneCodes;
          this.seedBreedingZoneName.forEach(elmt => {
            elmt.value = elmt.Id;
            elmt.label = elmt.Code + '-' + elmt.Name;
          });
        }
      },
      error: error => {
        if (error === '') {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.DisplayErrorMessage('Error', error);
        }
      }
  });
  }

  getLoginInfo() {
    let localRoles = this.sharedservice.getSessionStorageValue(Constants.UIUSERROLE);
    if (localRoles !== '' && localRoles !== null) {
      const userClaim = JSON.parse(localRoles || '{}') as UserClaim;
      this.userName = userClaim.username;
      this.isROVAdmin = userClaim.isROVAdmin;
      this.isROVUser = userClaim.isROVUser;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selectedTabIndex === 0) {
      this.getLoginInfo();
      this.buildForm();

      if( (this.cleanCache || (this.programAllData == null || this.programAllData.length === 0))
          && (changes['selectedTabIndex'] && changes['selectedTabIndex'].currentValue === 0)) {
        this.route.queryParams.subscribe(p=>{
          const programId: string = p['ProgramID'];
          this.selectedRequestID = programId ?? '';
        })

        this.cols = [
          { field: 'ProgramID', header: 'Program ID', hidden: 'true' },
          { field: 'ProgramCode', header: 'Program Code' },
          { field: 'ProgramName', header: 'Program Name' },
          { field: 'CropDescription', header: 'Crop' },
          { field: 'Region', header: 'RCZ', title: 'Research Commercial Zone' },
          { field: 'Station.StationCode', header: 'R&D Center' },
          { field: 'EvaluationZone.Code', header: 'Evaluation Zone' },
          { field: 'ProgramPrincipalInvestigatorNames', header: 'Principal Investigators' },
          { field: 'RequestStatus', header: 'Status' }
        ];

        this.getPrograms();
        this.getLookupTypes();
        this.bindProgramDropdownLists();
        this.getResponsibleSubfunctionLookUp();
      }
    }
  }

  clearDropdownSelection(dropdown: Dropdown, dropdown2: Dropdown, dropdown3: Dropdown, dropdown4: Dropdown, dropdown5: Dropdown) {
    if (dropdown.filterValue) {
      dropdown.filterValue = '';
    }
    if (dropdown2.filterValue) {
      dropdown2.filterValue = '';
    }
    if (dropdown3.filterValue) {
      dropdown3.filterValue = '';
    }
    if (dropdown4.filterValue) {
      dropdown4.filterValue = '';
    }
    if (dropdown5.filterValue) {
      dropdown5.filterValue = '';
    }
    if (this.programForm.StationID) {
      this.programForm.StationID = '';
    }
    if (this.programForm.ShipToStationID) {
      this.programForm.ShipToStationID = '';
    }
    if (this.programForm.EvaluationZoneID) {
      this.programForm.EvaluationZoneID = '';
    }
    if (this.programForm.SeedStorageLocationID) {
      this.programForm.SeedStorageLocationID = '';
    }
  }

  clearPT() {
    this.programForm.ProgramTypeID = '';
  }

  clearCrop(dropdown: Dropdown, dropdown2: Dropdown, dropdown3: Dropdown, dropdown4: Dropdown, dropdown5: Dropdown) {
    this.programForm.CropID = '';
    this.clearDropdownSelection(dropdown, dropdown2, dropdown3, dropdown4, dropdown5);
    this.allStationName = [];
    this.allShipToStationName = [];
    this.evalutionZones = [];
    this.allSeedStorageLocationName = [];
  }

  clearStation(dropdown2: Dropdown, dropdown5: Dropdown) {
    if (dropdown2.filterValue) {
      dropdown2.filterValue = '';
    }

    this.programForm.StationID = '';
    this.getSeedStorageLocationByCropID();
  }

  clearSTS(dropdown3: Dropdown, dropdown5: Dropdown) {
    if (dropdown3.filterValue) {
      dropdown3.filterValue = '';
    }
    this.programForm.ShipToStationID = '';
    this.getSeedStorageLocationByCropID();
  }

  clearEZ(dropdown4: Dropdown) {
    if (dropdown4.filterValue) {
      dropdown4.filterValue = '';
    }
    this.programForm.EvaluationZoneID = '';
  }

  clearSSL(dropdown5: Dropdown) {
    if (dropdown5.filterValue) {
      dropdown5.filterValue = '';
    }
    this.programForm.SeedStorageLocationID = '';
  }

  clearPP() {
    this.programForm.ProgramPurposeID = '';
  };

  getStationsByCropID() {
    this.allStationName = [];
    this.evalutionZones = [];
    this.allShipToStationName = [];
    this.breedingZones = [];
    const cropID = this.programForm.CropID;

    this.evalutionZones = this.seedEvaluationZoneName.filter(s => s.CropID === cropID);
    this.allStationName = this.programStationName.filter(s => s.StationCrops.find(elmt => elmt.CropID === cropID));
    this.allShipToStationName = this.shipToStationName.filter(s => s.StationCrops.find(elmt => elmt.CropID === cropID));
    this.breedingZones = this.seedBreedingZoneName.filter(s => s.CropID === cropID);
  }

  getSeedStorageLocationByCropID() {
    this.allSeedStorageLocationName = [];
    const cropID = this.programForm.CropID;
    const stationID = this.programForm.StationID;
    const shipToStationID = this.programForm.ShipToStationID;

    this.allSeedStorageLocationName = this.seedStorageLocationName.filter(s => s.CropID === cropID
      && (s.StationId === stationID || s.StationId === shipToStationID));
  }

  public setHeight() {
    const viewPortHeight = document.documentElement.clientHeight;
    const programGrid = document.getElementById('dvProgramGrid');

    if ( programGrid !== null) {
    const offsetHeight = programGrid.offsetTop;
    if (viewPortHeight <= 650) {
      return 10;
    } else {
      this.panelHeight = ((viewPortHeight) - (offsetHeight) - (200)).toString() + 'px';
      return 10;
    }
   }
    return 0;
  }

  getPrograms() {
    if(this.programAllData.length === 0) {
      this.loading = true;
      
      this._programService.getProgramResult().subscribe( {
        next: response => {
          this.filteredData = this.programAllData = response;

          if (response != null && this.selectedRequestID !== '') {
            const selectedItem = response.find(p => p.ProgramID.toLowerCase() === this.selectedRequestID.toLowerCase());
            this.first = 0;
            if(!selectedItem){
              this.DisplayInfoMessage('Info', `The Program
              requested in the URL either doesn't exist or is no more in Pending State.`);
            }
            else{
              let index = response.indexOf(selectedItem);
              const pageNumber = Math.ceil(++index / 10)
              this.first = (pageNumber - 1) * 10;
              this.expandedRows[this.selectedRequestID.toLowerCase()] = true;
              this.editPrograms(selectedItem);
            }
          }

          this.distinctAllDropdownCodes();
          this.loading = false;
        },
        error: error => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        }
      });
    }
  }

  distinctAllDropdownCodes() {
    const items = this.programAllData;

    let allCodes: string[] = items.map(p => p.ProgramCode);
    this.distinctCodes = this._commonService.GetDistinctSortedItems(allCodes);

    let allStationCodes: string[] = items.map(i => i.Station?.StationCode);
    this.distinctStations = this._commonService.GetDistinctSortedItems(allStationCodes);

     let allRegions: string[] = items.map(i => i.Region);
     this.distinctRegions = this._commonService.GetDistinctSortedItems(allRegions);

    let allEvaluationZoneCodes: string[] = items.map(i => i.EvaluationZone?.Code);
    this.distinctEZones = this._commonService.GetDistinctSortedItems(allEvaluationZoneCodes);
  }

  onCloneProgram(program: ProgramResultModel)
  {
    if(program.RequestStatus === RequestStatus.PENDING || program.RequestStatus === RequestStatus.ACTIVE) {
      localStorage.setItem("ToBeClonedProgram", JSON.stringify(program));
      this.router.navigate(['/create','Program']);
    }
    else {
      this.DisplayInfoMessage('Cloning', 'Only Active or Pending records can be cloned');
    }
  }

  editPrograms(program: ProgramResultModel) {
    if (this.isROVAdmin) {
      this.programFormGroup.controls['RequestStatus'].enable();
    } else {
      this.programFormGroup.controls['RequestStatus'].disable();
    }

    this.buildForm();
    this.programForm = Object.assign({}, program);
    this.getStationsByCropID();
    this.getSeedStorageLocationByCropID();

    if (!this.programForm.StationID || !this.allStationName.find(s => s.StationID.toString()
      === this.programForm.StationID.toString())) {
      this.programForm.StationID = '';
    }

    if (!this.programForm.ShipToStationID || !this.allShipToStationName.find(s => s.StationID.toString()
      === this.programForm.ShipToStationID.toString())) {
      this.programForm.ShipToStationID = '';
    }

    if (!this.programForm.SeedStorageLocationID || !this.allSeedStorageLocationName.find(s => s.Id.toString()
      === this.programForm.SeedStorageLocationID.toString())) {
      this.programForm.SeedStorageLocationID = '';
    }

    if (!this.programForm.EvaluationZoneID || !this.evalutionZones.find(s => s.Id.toString()
      === this.programForm.EvaluationZoneID.toString())) {
      this.programForm.EvaluationZoneID = '';
    }

    if (!this.programForm.ProgramTypeID || !this.programTypeName.find(s => s.Id.toString()
      === this.programForm.ProgramTypeID.toString())) {
      this.programForm.ProgramTypeID = '';
    }

    if (!this.programForm.ProgramPurposeID || !this.programPurposeName.find(s => s.Id.toString()
      === this.programForm.ProgramPurposeID.toString())) {
      this.programForm.ProgramPurposeID = '';
    }

    this.selectedBZ = [];

    if (program.ProgramBreedingZoneAssoc != null) {
      this.seedBreedingZoneName.forEach(elmt => {
        program.ProgramBreedingZoneAssoc.filter(ua => ua.BreedingZoneID === elmt.Id).forEach(e2 => {
          this.selectedBZ.push(e2.BreedingZoneID);
        });
      });
    }

    this.programLead = [];
    if (program.ProgramPrincipalInvestigators != null) {
      program.ProgramPrincipalInvestigators.forEach(pr => {
        this.programLead.push({ label: pr.Person.DisplayName, value: pr.Person.DisplayName });
      });
    }

    this.dialogVisible = true;

    this.status = [];

    if (this.isROVAdmin) {
      this.status.push({ label: RequestStatus.ACTIVE, value: RequestStatus.ACTIVE });
      this.status.push({ label: RequestStatus.DECLINED, value: RequestStatus.DECLINED });
    }
    this.status.push({ label: RequestStatus.PENDING, value: RequestStatus.PENDING });

    if (program.RequestStatus !== RequestStatus.PENDING) {
      let index = this.status.findIndex(s => s.value === RequestStatus.DECLINED);

      if (index !== -1) {
        this.status.splice(index, 1);
      }

      index = this.status.findIndex(s => s.value === RequestStatus.PENDING);

      if (index !== -1) {
        this.status.splice(index, 1);
      }
      this.status.push({ label: RequestStatus.RETIRED, value: RequestStatus.RETIRED });
      this.status.push({ label: RequestStatus.TRANSITIONING, value: RequestStatus.TRANSITIONING });
    }

    this.selectedStatus = this.status.find(s => s.label === program.RequestStatus)?.value;
    this.programForm.RequestStatus = this.selectedStatus;

    this.programForm.ProgramID = program.ProgramID;
    this.programForm.BaseProgramID = program.BaseProgramID;

    this.programForm.ApprovalComments = [];
    this.programFormGroup.controls['Comment'].setValue('');

    this.statusChange(this.programForm.RequestStatus, false);

    if (this.isROVAdmin) {
      this.getLEData(this.programForm.ProgramCode);
    }
  }

changeBZ(event: any) {
    this.programForm.ProgramBreedingZoneAssoc = [];

    event.value.forEach((id: string) => {
      this.seedBreedingZoneName.filter(s => s.Id === id).forEach(item => {
        this.programForm.ProgramBreedingZoneAssoc.push({
          ProgramID: this.programForm.ProgramID,
          BreedingZoneID: item.Id,
          Program: new ProgramResultModel,
          BreedingZone: new BreedingZoneResultModel
        });
      });
    });
  }

  getLEData(code: string) {
    if (code) {
      const queryString = `code=${code}&type=${this.parentComponent}`;

      this.projectLEResult = [];
      this.stationLEResult = [];
      this.sSLLEResult = [];
      this.locationProjectCodeResult = [];
      this.experimentProjectCodeResult = [];
      this.leIDs = [];

      this._commonService.getLEAndAOIReportData(queryString)
        .subscribe( {
         next: Result => {
            this.projectLEResult = Result.ProjectLE;
            this.projectLEResult = this.projectLEResult.slice();

            if (this.projectLEResult && this.projectLEResult.length > 0) {
              this.projectLEResult.forEach(s => {
                this.leIDs.push(s.LEId);
              });
            }

            this.stationLEResult = Result.StationLE;
            this.stationLEResult = this.stationLEResult.slice();

            if (this.stationLEResult && this.stationLEResult.length > 0) {
              this.stationLEResult.forEach(s => {
                this.leIDs.push(s.LEId);
              });
            }

            this.sSLLEResult = Result.SSLLE;
            this.sSLLEResult = this.sSLLEResult.slice();

            this.locationProjectCodeResult = Result.LocationProjectCode;
            this.locationProjectCodeResult = this.locationProjectCodeResult.slice();

            this.experimentProjectCodeResult = Result.ExperimentProjectCode;
            this.experimentProjectCodeResult = this.experimentProjectCodeResult.slice();
          },
          error: error => {
            if (error === '') {
              this.DisplayErrorMessage('Error', Constants.NOSERVICE);
            } else {
              this.DisplayErrorMessage('Error', error);
            }
          },
          complete:()=>{
            this.dataloaded = true;

          }
         } );
    }
  }

  addLeads() {
    this.existingPeople = [];

    if (this.programForm.ProgramPrincipalInvestigators) {
      this.programForm.ProgramPrincipalInvestigators.forEach(Investigator => {
        this.existingPeople.push(Investigator.Person);
      });
    }

    const obj = new UserRequestedData();
    obj.ParentComponent = this.parentComponent;
    obj.person = this.existingPeople;
    obj.singleLead = false;
    this.existingPeopleString = JSON.stringify(obj);
    this.displayInvestigator = true;
  }

  filterProgramDataTable(event: any) {
    this.filteredData = event.filteredValue;
  }

  exportCSVForPrograms() {
    if (this.filteredData == null || this.filteredData.length === 0) {
      this.DisplayErrorMessage('Error', Constants.NOTHINGTOEXPORT);
      return;
    }

    const exportData = { 'IncludeProgramSize': this.includeProgramSize, 'Programs': this.filteredData };
    this._programService.exportCSVForPrograms(JSON.stringify(exportData))
      .subscribe({
       next: data => {
          const blob = new Blob([data], { type: 'application/ms-excel'});
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = window.URL.createObjectURL(blob);
          link.download = 'ProgramData.xlsx';
          document.body.appendChild(link);
          link.click();

          setTimeout(function () {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
          }, 100);
        },
       error: error => {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        }
       } );
  }

  public DisplayErrorMessage(title: string, message: string) {
    this._commonService.DisplayErrorMessage(title, message);
  }

  public DisplayWarningMessage(title: string, message: string) {
    this._commonService.DisplayWarningMessage(title, message);
  }

  public DisplayInfoMessage(title: string, message: string) {
    this._commonService.DisplayInfoMessage(title, message);
  }

  public DisplaySuccessMessage(title: string, message: string) {
    this._commonService.DisplaySuccessMessage(title, message);
  }

  onCloseEditProgram(event: any, frm: any) {
    setTimeout(() => {
      this.close(frm);
    }, 100);
  }

  close(frm: any) {
    if (frm.dirty) {
      this._confirmation.confirm({
        message: Constants.PENDINGCHANGES,
        accept: () => {
          this.dialogVisible = false;
          this.existingPeople = [];
          this.existingPeopleString = '';
          this.buildForm();
        }
      });
    } else {
      this.dialogVisible = false;
      this.existingPeople = [];
      this.existingPeopleString = '';
    }
  }

  setSaveButtonStyle(status: string) {
    if (status === RequestStatus.PENDING) {
      this.saveIcon = 'fa fa-save';
      this.saveIconClass = 'p-button-primary';
      this.saveLabelText = 'Save';
    } else if (status === RequestStatus.ACTIVE) {
      this.saveIcon = 'fa fa-check';
      this.saveIconClass = 'p-button-secondary';
      this.saveLabelText = 'Mark as Active';
    } else if (status === RequestStatus.DECLINED) {
      this.saveIcon = 'fa fa-trash';
      this.saveIconClass = 'p-button-danger';
      this.saveLabelText = 'Mark as Declined';
    } else if (status === RequestStatus.RETIRED) {
      this.saveIcon = 'fa fa-ban';
      this.saveIconClass = 'p-button-danger';
      this.saveLabelText = 'Mark as Retired';
    } else if (status === RequestStatus.TRANSITIONING) {
      this.saveIcon = 'fa fa-save';
      this.saveIconClass = 'p-button-info';
      this.saveLabelText = 'Mark as Transitioning';
    }

    this.saveIconClass = this.saveIconClass + ' p-button p-widget p-state-default p-corner-all p-button-text-icon-left';
  }

  statusChange(status: string,isChange:boolean) {
    if(this.programForm.LEID =="0")
    {
      (this.programForm.LEID = "");
    }
    this.setSaveButtonStyle(status);
    if (status === this.selectedStatus) {
      this.saveLabelText = 'Save';
    }
    if (this.programForm.RequestStatus === RequestStatus.RETIRED || this.programForm.RequestStatus === RequestStatus.TRANSITIONING) {
      this.programFormGroup = this._fb.group({
        'ProgramName': [this.programForm.ProgramName, Validators.required],
        'ProgramCode': [this.programForm.ProgramCode, Validators.required],
        'ProgramPrincipalInvestigatorNames': [this.programForm.ProgramPrincipalInvestigatorNames],
        'Description': [this.programForm.Description],
        'ProgramTypeID': [this.programForm.ProgramTypeID],
        'ProgramSize': [this.programForm.ProgramSize],
        'CropID': [this.programForm.CropID],
        'StationID': [this.programForm.StationID],
        'ShipToStationID': [this.programForm.ShipToStationID],
        'SeedStorageLocationID': [this.programForm.SeedStorageLocationID],
        'EvaluationZoneID': [this.programForm.EvaluationZoneID],
        'ProgramPurposeID': [this.programForm.ProgramPurposeID],
        'CRMLower': [this.programForm.CRMLower],
        'CRMUpper': [this.programForm.CRMUpper],
        'RequestStatus': [this.programForm.RequestStatus],
        'LEID': [this.programForm.LEID, Validators.required],
        'Comment': [this.programForm.ApprovalComments],
        'ProgramBreedingZoneAssoc': [this.selectedBZ]
      });
    } else {
      this.buildForm();
    }
    if(isChange)
    this.programFormGroup.markAsDirty();
  }

  saveProgram(frm: any, status: any) {
    if (status === RequestStatus.DECLINED) {
      this._confirmation.confirm({
        message: Constants.CONFIRMDECLINE,
        accept: () => {
          this.save(frm, status);
        }
      });
    } else {
      this.save(frm, status);
    }
  }

  save(frm: any, status: string) {
    this.programForm.EvaluationZone = this.seedEvaluationZoneName.find(s => s.value === this.programForm.EvaluationZoneID)!;
    this.programForm.ProgramPurpose = this.programPurposeName.find(s => s.value === this.programForm.ProgramPurposeID)!;
    this.programForm.ProgramType = this.programTypeName.find(s => s.value === this.programForm.ProgramTypeID)!;
    this.programForm.SeedStorageLocation = this.seedStorageLocationName.find(s => s.value === this.programForm.SeedStorageLocationID)!;
    this.programForm.Station = this.programStationName.find(s => s.value === this.programForm.StationID);
    this.programForm.ShipToStation = this.shipToStationName.find(s => s.value === this.programForm.ShipToStationID);
    this.programForm.PrimaryResponsibleSubFunction = this.responsiblesubfunctions.find(s => s.value === this.programForm.PrimaryResponsibleSubFunctionId)!;
    //this.programForm.ResponsibleSubFunctionId = this.ResponsibleSubFunction.find(s => s.value === this.programForm.ResponsibleSubFunctionId);
    const crop = this.programCropName.find(s => s.value === this.programForm.CropID);
    if (crop) {
      this.programForm.CropID = crop.CropID;
      this.programForm.CropName = crop.CropName;
      this.programForm.CropDescription = crop.CropDescription;
    } else {
      this.programForm.CropID = '';
      this.programForm.CropName = '';
      this.programForm.CropDescription = '';
    }

    let bzNames = '';

    if (this.programForm.ProgramBreedingZoneAssoc) {
      this.programForm.ProgramBreedingZoneAssoc.forEach(s => {
        const breedingZone = this.seedBreedingZoneName.find(p => p.Id === s.BreedingZoneID);
        if (breedingZone != null) {
          bzNames = bzNames + breedingZone.Code + ', ';
        }
      });

      bzNames = bzNames.trim();

      if (bzNames.lastIndexOf(',') === bzNames.length - 1) {
        bzNames = bzNames.substring(0, bzNames.lastIndexOf(','));
      }
    }

    this.programForm.BreedingZones = bzNames;

    this.existingPeople = [];
    if (this.programForm.ProgramPrincipalInvestigators) {
      this.programForm.ProgramPrincipalInvestigators.forEach(s => {
        this.existingPeople.push(s.Person);
      });
    }

    this.programData = Object.assign({}, this.programForm);

    if (!this.programData.ProgramCode) {
      this.programData.ProgramCode = '';
    }

    if (status === RequestStatus.DECLINED || this.validateForm(status !== RequestStatus.PENDING && status !== RequestStatus.DECLINED)) {
      this.programData.RequestStatus = status;

      this._programService.saveProgram(JSON.stringify(this.programData))
        .subscribe({
          next:Result => {
            if (Result.Status === 'SUCCESS') {
              const primaryID = this.programData.ProgramID;
              this.programData = Result.Program;

              const i = this.programAllData.findIndex(s => s.ProgramID === primaryID);
              const j = this.expandedItems.findIndex(s => s.ProgramID === primaryID);
              const k = this.programCodes.findIndex(s => s.ProgramID === primaryID);

              if (Result.Program.RequestStatus === RequestStatus.DECLINED) {
                if (i !== -1) {
                  this.programAllData.splice(i, 1);
                }
                if (j !== -1) {
                  this.expandedItems.splice(j, 1);
                }
                if (k !== -1) {
                  this.programCodes.splice(k, 1);
                }
              } else {
                this.programAllData[i] = this.programData;
                this.expandedItems[j] = this.programData;

                if (k !== -1) {
                  this.programCodes.splice(k, 1, ({
                    ProgramID: this.programData.ProgramID,
                    ProgramCode: this.programData.ProgramCode,
                    ProgramName: this.programData.ProgramName,
                    CropID: this.programData.CropID,
                    RequestStatus: this.programData.RequestStatus,
                    EvaluationZoneID: '',
                    label: this.programData.ProgramCode + ' - ' + this.programData.ProgramName,
                    value: this.programData.ProgramID
                  }));
                }

                this.cleanUpCache.emit(true);
              }

              this.programCodes = this.programCodes.slice();
              this.expandedItems = this.expandedItems.slice();
              this.programAllData = this.programAllData.slice();
              this.distinctAllDropdownCodes();

              if (this.selectedStatus === RequestStatus.PENDING && Result.Program.RequestStatus !== RequestStatus.PENDING) {
                this._appService.sendPendingCount(-1);
              }

              this.selectedStatus = status;
              this.DisplaySuccessMessage('Success', Result.Message);
            } else {
              this.programData.RequestStatus = this.selectedStatus;
              this.DisplayErrorMessage('Error', Result.Message);
            }
            this.buildForm();
            this.dialogVisible = false;
          },
          error:error => {
            this.programData.RequestStatus = this.selectedStatus;
            if (error === '') {
              this.DisplayErrorMessage('Error', Constants.NOSERVICE);
            } else {
              this.DisplayErrorMessage('Error', error);
            }
          }
    });
    }
  }

  validateForm(isApprove: boolean) {
    if (!this.programForm.ProgramName || this.programForm.ProgramName.trim() === '') {
      this.DisplayWarningMessage('Warning', 'Program Name is required...');
      return false;
    }

    if (isApprove && (!this.programForm.ProgramCode
      || this.programForm.ProgramCode.trim() === '')) {
      this.DisplayWarningMessage('Warning', 'Program Code is required before approval...');
      return false;
    }

    if ((this.programForm.ProgramCode
      && (this.programForm.ProgramCode.trim().length < 2 || this.programForm.ProgramCode.trim().length > 4))) {
      this.DisplayWarningMessage('Warning', 'Program Code should be between 2 and 4 characters long...');
      return false;
    }

    if (isApprove && (!this.programForm.ProgramSize && this.programForm.ProgramSize !== 0)
      && this.programForm.RequestStatus !== RequestStatus.RETIRED) {
      this.DisplayWarningMessage('Warning', 'Program Size is required before approval...');
      return false;
    }

    if ((this.programForm.ProgramSize || this.programForm.ProgramSize === 0)
      && (parseFloat(this.programForm.ProgramSize.toString()) < 0 || parseFloat(this.programForm.ProgramSize.toString()) > 5)) {
      this.DisplayWarningMessage('Warning', 'Program Size should be between 0 and 5...');
      return false;
    }

    if ((this.programForm.ProgramSize || this.programForm.ProgramSize === 0)
      && this.programForm.ProgramSize.toString().indexOf('.') >= 0
      && (parseFloat(this.programForm.ProgramSize.toString()).toString().split('.')[1].length) > 2
      && this.programForm.RequestStatus !== RequestStatus.RETIRED) {
      this.DisplayWarningMessage('Warning', 'Program Size allowed upto 2 decimal places...');
      return false;
    }

    if (isApprove && (!this.programForm.ProgramType
      || (this.programForm.ProgramType && this.programForm.ProgramType.Name === ''))
      && this.programForm.RequestStatus !== RequestStatus.RETIRED) {
      this.DisplayWarningMessage('Warning', 'Program Type is required before approval...');
      return false;
    }

    if ((!this.programForm.CropName || this.programForm.CropName === '')
      && this.programForm.RequestStatus !== RequestStatus.RETIRED) {
      this.DisplayWarningMessage('Warning', 'Crop is required...');
      return false;
    }

    if (this.programForm.ProgramCode && this.programForm.ProgramCode.toString().trim() !== '') {
      const pCode = this.programCodes.find(s => s.ProgramCode.toString().toLowerCase()
        === this.programForm.ProgramCode.toString().toLowerCase() && s.CropID === this.programForm.CropID
        && s.ProgramID !== this.programForm.ProgramID && s.RequestStatus !== RequestStatus.RETIRED);

      if (pCode && this.programForm.RequestStatus !== RequestStatus.RETIRED) {
        this.DisplayWarningMessage('Warning', 'This Program Code and Crop combo already exists. Please enter a new Code or Crop...');
        return false;
      }
    }

    if ((!this.programForm.Station
      || (this.programForm.Station && this.programForm.Station.StationName === ''))
      && this.programForm.RequestStatus !== RequestStatus.RETIRED) {
      this.DisplayWarningMessage('Warning', 'R&D Center Name is required...');
      return false;
    }

    if (isApprove && (!this.programForm.EvaluationZone
      || (this.programForm.EvaluationZone && this.programForm.EvaluationZone.Name === ''))
      && this.programForm.RequestStatus !== RequestStatus.RETIRED) {
      this.DisplayWarningMessage('Warning', 'Evaluation Zone is required before approval...');
      return false;
    }

    if (isApprove && (!this.programForm.ProgramPurpose
      || (this.programForm.ProgramPurpose && this.programForm.ProgramPurpose.Name === ''))
      && this.programForm.RequestStatus !== RequestStatus.RETIRED) {
      this.DisplayWarningMessage('Warning', 'Program Purpose is required before approval...');
      return false;
    }

    if (isApprove && ((this.programForm.CRMLower === undefined || this.programForm.CRMLower == null)
      || (this.programForm.CRMLower && this.programForm.CRMLower.toString().trim() === ''))
      && this.programForm.RequestStatus !== RequestStatus.RETIRED) {
      this.DisplayWarningMessage('Warning', 'CRM Lower is required before approval...');
      return false;
    }

    if (this.programForm.CRMLower
      && (this.programForm.CRMLower.toString().indexOf('.') >= 0)) {
      this.DisplayWarningMessage('Warning', 'Decimal value is not allowed for CRM Lower...');
      return false;
    }

    if (this.programForm.CRMLower
      && this.programForm.CRMLower === parseInt(this.programForm.CRMLower.toString(), 10)
      && (this.programForm.CRMLower < -255 || this.programForm.CRMLower > 255)
      && this.programForm.RequestStatus !== RequestStatus.RETIRED) {
      this.DisplayWarningMessage('Warning', 'CRM Lower should be between -255 to 255 digits long...');
      return false;
    }

    if (isApprove && ((this.programForm.CRMUpper === undefined || this.programForm.CRMUpper == null)
      || (this.programForm.CRMUpper && this.programForm.CRMUpper.toString().trim() === ''))
      && this.programForm.RequestStatus !== RequestStatus.RETIRED) {
      this.DisplayWarningMessage('Warning', 'CRM Upper is required before approval...');
      return false;
    }

    if (this.programForm.CRMUpper
      && (this.programForm.CRMUpper.toString().indexOf('.') >= 0)) {
      this.DisplayWarningMessage('Warning', 'Decimal value is not allowed for CRM Upper...');
      return false;
    }

    if (this.programForm.CRMUpper
      && this.programForm.CRMUpper === parseInt(this.programForm.CRMUpper.toString(), 10)
      && (this.programForm.CRMUpper < -255 || this.programForm.CRMUpper > 255)
      && this.programForm.RequestStatus !== RequestStatus.RETIRED) {
      this.DisplayWarningMessage('Warning', 'CRM Upper should be between -255 to 255 digits long...');
      return false;
    }

    if (!(this.programForm.CRMUpper === undefined || this.programForm.CRMUpper == null)
      && !(this.programForm.CRMLower === undefined || this.programForm.CRMLower == null)
      && (this.programForm.CRMUpper === parseInt(this.programForm.CRMUpper.toString(), 10)
        && this.programForm.CRMLower === parseInt(this.programForm.CRMLower.toString(), 10)
        && parseInt(this.programForm.CRMUpper.toString(), 10) < parseInt(this.programForm.CRMLower.toString(), 10))) {
      this.DisplayWarningMessage('Warning', 'CRM Upper should be greater than CRM Lower...');
      return false;
    }

    if (this.programForm.RequestStatus === RequestStatus.PENDING
      && this.selectedStatus !== RequestStatus.PENDING) {
      this.DisplayWarningMessage('Warning', 'Status cannot be reverted back to Pending...');
      return false;
    }

    if (this.programForm.RequestStatus !== (RequestStatus.PENDING || RequestStatus.RETIRED)) {
      if (isApprove && (!this.programForm.LEID
        || (this.programForm.LEID
          && this.programForm.LEID.toString().trim() === ''))) {
        this.DisplayWarningMessage('Warning', 'LE ID is required for Request Status other than Pending and Retired...');
        return false;
      }

      const idx = this.leIDs.findIndex(s => s.toString() === this.programForm.LEID.toString());

      if (isApprove && (this.programForm.LEID === '0' || idx === -1)
        && this.programForm.RequestStatus !== RequestStatus.RETIRED) {
        this.DisplayWarningMessage('Warning', 'LE ID is not a valid LE ID...');
        return false;
      }
    }

    return true;
  }

  viewComments(program: ProgramResultModel) {
    this.approvalCommentData = [];

    if (program.ApprovalComments != null) {
      program.ApprovalComments.forEach(element => {
        this.approvalCommentData.push({
          CommentDateString: this.ConvertDateToString(element.CommentDate),
          CommentDate: element.CommentDate,
          Commenter: element.Commenter.DisplayName,
          Comment: element.Comment
        });
      });
    }

    this.approvalCommentData = this.approvalCommentData.slice();
    this.displayComments = true;

    this.sourceIdentifier = program.ProgramID;
  }

  onSaveComment(data: any){
    this.saveComment(data.comment, data.sourceIdentifier);
  }

  saveComment(comment: string, identifier: string) {
    const programID = identifier;

    this.disableCommentButton = true;

    const approvalComments = new ApprovalComments();
    approvalComments.Comment = comment;
    approvalComments.RequestTypeID = programID;

    this._programService.saveProgramComment(JSON.stringify(approvalComments))
      .subscribe({
       next: Result => {
          if (Result.Status === 'SUCCESS') {
            const programIndex = this.programAllData
              .findIndex(s => s.ProgramID.toString() === programID.toString());

            if (programIndex !== -1) {
              this.programAllData[programIndex] = Result.Program;
              this.programAllData[programIndex].ApprovalComments = Result.Program.ApprovalComments.slice();
              this.programAllData = this.programAllData.slice();

              const expandedIndex = this.expandedItems
                .findIndex(s => s.ProgramID = Result.Program.ProgramID);

              this.expandedItems[expandedIndex] = Result.Program;
              this.expandedItems = this.expandedItems.slice();

              this.approvalCommentData = [];

              if (Result.Program.ApprovalComments != null) {
                Result.Program.ApprovalComments.forEach((element: { CommentDate: Date; Commenter: any; Comment: any; }) => {
                  this.approvalCommentData.push({
                    CommentDateString: this.ConvertDateToString(element.CommentDate),
                    CommentDate: element.CommentDate,
                    Commenter: element.Commenter.DisplayName,
                    Comment: element.Comment
                  });
                });
              }
            }

            this.approvalCommentData = this.approvalCommentData.slice();
            this.DisplaySuccessMessage('Success', Result.Message);
            this.displayComments = false;

          } else {
            this.DisplayErrorMessage('Error', Result.Message);
          }
          this.disableCommentButton = false;
        },
       error: error => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
          this.disableCommentButton = false;
        }
  });
  }

  GetLEID(program: ProgramForm) {
    if (program.ProgramCode) {
      if (!program.ProgramCode) {
        this.DisplayErrorMessage('Error', 'Please enter the Program Code first...');
        return;
      }

      if (!program.Description) {
        this.DisplayErrorMessage('Error', 'Please enter the Description first...');
        return;
      }

      const obj = new LERequestedData();
      obj.ParentComponent = this.parentComponent;
      obj.LEAOIID = 0;

      if (!this.programFormGroup.controls['ProgramCode'].dirty) {
        obj.Code = '';
        obj.ProjectLE = this.projectLEResult;
        obj.StationLE = this.stationLEResult;
        obj.SSLLE = this.sSLLEResult;
        obj.LocationProjectCode = this.locationProjectCodeResult;
        obj.ExperimentProjectCode = this.experimentProjectCodeResult;
      } else {
        obj.Code = program.ProgramCode;
        obj.ProjectLE = [];
        obj.StationLE = [];
        obj.SSLLE = [];
        obj.LocationProjectCode = [];
        obj.ExperimentProjectCode = [];
      }

      obj.OtherObject = JSON.stringify(program);

      this.existingLEIDString = JSON.stringify(obj);
      this.displayLEIDWindow = true;
    } else {
      this.DisplayErrorMessage('Error', 'Please enter the Code first...');
    }
  }

  onaddLEDataClick(data: LERequestedData): void {
    if (!(data == null && data === undefined) && data.ParentComponent === this.parentComponent) {
      this.displayLEIDWindow = false;

      this.programForm.LEID = data.LEAOIID.toString();

      this.projectLEResult = data.ProjectLE;

      this.leIDs = [];

      if (this.projectLEResult && this.projectLEResult.length > 0) {
        this.projectLEResult.forEach(s => {
          this.leIDs.push(s.LEId);
        });
      }

      this.stationLEResult = data.StationLE;

      if (this.stationLEResult && this.stationLEResult.length > 0) {
        this.stationLEResult.forEach(s => {
          this.leIDs.push(s.LEId);
        });
      }

      this.sSLLEResult = data.SSLLE;
      this.locationProjectCodeResult = data.LocationProjectCode;
      this.experimentProjectCodeResult = data.ExperimentProjectCode;

      this.existingLEIDString = '';
    }
  }

  onaddUserDataClick(data: UserRequestedData): void {
    if (!(data == null && data === undefined) && data.ParentComponent === this.parentComponent) {
      this.displayInvestigator = false;
      let leadNames = '';

      this.programForm.ProgramPrincipalInvestigators = [];
      this.programForm.ProgramPrincipalInvestigatorNames = '';
      this.programLead = [];

      data.person.forEach(pr => {
        if (!this.programForm.ProgramPrincipalInvestigators.find(s => s.Person.LoginName === pr.LoginName)) {
          this.programForm.ProgramPrincipalInvestigators.push({
            ID: '', PersonID: pr.PersonID, Person: pr, Program: null,
            ProgramID: this.programForm.ProgramID
          });
          this.programLead.push({ label: pr.DisplayName, value: pr.DisplayName });
        }

        leadNames = leadNames + pr.DisplayName + '; ';
      });

      leadNames = leadNames.trim();

      if (leadNames.lastIndexOf(';') === leadNames.length - 1) {
        leadNames = leadNames.substring(0, leadNames.lastIndexOf(';'));
      }

      this.programForm.ProgramPrincipalInvestigatorNames = leadNames;
      this.existingPeopleString = '';
    }
  }
}