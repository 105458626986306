import { SeedStorageLocationType } from './../shared/seedStorageLocationType';
import { Station } from './../shared/station';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ConfirmationService, SelectItem } from 'primeng/api';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SeedStorageLocationService } from '.././seedstoragelocation/seedstoragelocation.service';
import { SeedStorageLocationModel } from '.././shared/seedStorageLocationModel';
import { SSLForm } from '.././shared/seedStorageLocationFormControls';
import { CommonService } from '.././shared/common.service';
import { Message } from 'primeng/api';
import { Person } from '.././shared/person';
import { NgForm } from '@angular/forms';
import { UserRequestedData } from '.././shared/userRequestedData';
import { CropModel } from './../shared/cropModel';
import { SSLModel } from './../shared/sslModel';
import { Constants } from './../shared/constants';
import { RequestStatus } from '../shared';
import { ApprovalComments } from '.././shared/approvalComments';
import { GeoPoliticalUnitModel } from './../shared/geoPoliticalUnitModel';
import { AppService } from '../app.service';
import { ActivatedRoute, CanDeactivate } from '@angular/router';
import { ClearClonedData } from '../createprogram/createprogram.component';

@Component({
  selector: 'app-createseedstoragelocation',
  templateUrl: './createseedstoragelocation.component.html',
  styleUrls: ['./createseedstoragelocation.component.scss']
})
export class CreateseedstoragelocationComponent implements OnInit, OnChanges, CanDeactivate<ClearClonedData> {

  msgs: Message[] = [];
  public displayStationLead = false;
  public keyContactsLead: SelectItem[] = [];
  public existingPeople: Person[] = [];
  public existingPeopleString = '';
  public sslCropName: CropModel[] = [];
  public sslTypeName: SeedStorageLocationType[] = [];
  public sslCountryName: GeoPoliticalUnitModel[] = [];
  public sslStationName: Station[] = [];
  public allStationName: Station[] = [];
  public sslName: SSLModel[] = [];
  public sslData: SeedStorageLocationModel = new SeedStorageLocationModel();
  public parentComponent = 'CreateSSL'; 
  public displaySeedStorageLocationTypeDialog: boolean;
  //seedStorageLocationtypes: LookupType[];
  public SeedStorageLocationType: string = Constants.LOOKUPTYPESEEDSTORAGELOCATIONTYPE;
  public comments = '';

  public sslFormGroup: FormGroup;
  public sslForm = new SSLForm(
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '', 
    '', 
    '',
    /* 0,
    '',
    0,
    '',
    0,
    '',
    0,
    '',
    0,
    '',
    0,
    '',
    0,
    '',
    0,
    '', */
    '',
    [],
    '',
    0,
    '',
    false,
    '',
    '',
    '',
    '',
    '',
    '',
    0,
    [],
    new Person,
    '',
    new Date(),
    new Person(),
    '',
    new Date(),
    new Person(),
    '',
    new Date(),
    '',
    new Person(),
    new Date(),
    ''
  );

  @Input()
  public selectedTabIndex: number;

  constructor(private _commonService: CommonService, private _sslService: SeedStorageLocationService,
    private _appService: AppService, private _fb: FormBuilder, private route: ActivatedRoute,
    private _confirmation: ConfirmationService) { }

  ngOnInit() {
    this.buildForm();
    this.sslData.RequestStatus = RequestStatus.PENDING;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selectedTabIndex === 2) {
      this.getCrops();
      this.getSSLTypes();
      this.GetAllCountriesFomPRISM(); 
      this.getSeedStorageLocationTypeLookUp(); 

      var typeValue = this.route.snapshot.paramMap.get("type");
      if (typeValue) {
        this.setupClone();
      }
    }
  }

  canDeactivate(component: ClearClonedData): boolean {
    localStorage.removeItem("ToBeClonedSSL");
    return true;
  }

  setupClone(): void {
    const dataToBeCloned = localStorage.getItem("ToBeClonedSSL");
    if (dataToBeCloned && dataToBeCloned !== 'undefined') {
      var data = JSON.parse(dataToBeCloned) as SeedStorageLocationModel;
      this.sslData = data;
      
      this.sslData.SeedStorageLocationID = "";
      this.sslData.CropID = ""; 
      this.sslData.CropName = ""; 
      this.sslData.CropDescription = ""; 
      this.sslData.StationId = ""; 
      this.sslData.StationName = "";  
      this.sslData.StationCode = "";  
      this.sslData.Station = null;   
      this.sslData.PrismLeId = ""; 
      this.sslData.BaseSeedStorageLocationID = ""; 
      this.sslData.WWSAddessId = ""; 
      this.sslData.WWSShippingProject= "";
      this.sslData.RequestStatus = RequestStatus.PENDING;

      if (this.sslData.SeedStorageLocationKeyContacts != null) {
        this.sslData.SeedStorageLocationKeyContacts.forEach(pr => {
          this.keyContactsLead.push({ label: pr.Person.DisplayName, value: pr.Person.DisplayName });
        });
      }
    }
  }

  buildForm(): void {
    this.sslFormGroup = this._fb.group({
      'SSLName': [this.sslForm.Name, Validators.required],
      'StationId': [this.sslForm.StationId, Validators.required],
      'StationCode': [this.sslForm.StationCode],
      'StationName': [this.sslForm.StationName],
      'SeedStorageLocationTypeId': [this.sslForm.SeedStorageLocationTypeId],
      'SeedStorageLocationTypeCode': [this.sslForm.SeedStorageLocationTypeCode],
      'SeedStorageLocationTypeName': [this.sslForm.SeedStorageLocationTypeName],
      'CropID': [this.sslForm.CropID, Validators.required],
      'CropName': [this.sslForm.CropName],
      'KeyContacts': [this.sslForm.SeedStorageLocationKeyContactNames],
      'Description': [this.sslForm.Description],
      'StatusId': [this.sslForm.StatusId],
      'RequestStatus': [this.sslForm.RequestStatus],
      'Virtual': [this.sslForm.IsVirtual],
      'R3BuildingCode': [this.sslForm.R3BuildingCode],
      'WWSAddessId': [this.sslForm.WWSAddessId],
      'WWSShippingProject': [this.sslForm.WWSShippingProject],
      'PrismLeId': [this.sslForm.PrismLeId],
      'Comment': [this.sslForm.ApprovalComments]
    });
  }

  GetAllCountriesFomPRISM() {
    this._sslService.GetAllCountriesFomPRISM().subscribe({     
       next: data => {
        if (data.SeedStorageLocationNames && data.SeedStorageLocationNames.length > 0) {
          this.sslName = [];

          data.SeedStorageLocationNames.forEach((item: any) => {
            this.sslName.push({
              Id: item.Id,
              Name: item.Name,
              CropID: item.CropId,
              Description: item.Description,
              RequestStatus: item.RequestStatus,
              label: item.Name,
              value: item.Id
            });
          });
        }
        if (data.Countries && data.Countries.length > 0) {
          this.sslCountryName = data.Countries;
          this.sslCountryName.forEach(element => {
            element.value = element.AoiId;
            element.label = element.Name;
          });
        }

        if (data.Stations && data.Stations.length > 0) {
          this.sslStationName = data.Stations;
          this.sslStationName.forEach(element => {
            element.value = element.StationID;
            element.label = element.StationCode + ' - ' + element.StationName;
          });
        }
      },
      error: error => {
        if (error === '') {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.DisplayErrorMessage('Error', error);
        }
      }
  });
  } 

  showSeedStorageLocationTypeDialog() {
    this.displaySeedStorageLocationTypeDialog = true;
  }  

  public getSeedStorageLocationTypeLookUp() {
    this._commonService.getLookupTypes(Constants.LOOKUPTYPESEEDSTORAGELOCATIONTYPE, true)
      .subscribe({
        next: (response) => {
          this.sslTypeName = response;

          this.sslTypeName.forEach(element => {
            element.value = element.Id;
            element.label = element.Name;
          });
        },
        error: (error) => {
        }
      });
  }

  getCrops() {
    this._commonService.getCrops().subscribe({
      next: data => {
        this.sslCropName = data;
        this.sslCropName.forEach(element => {
          element.value = element.CropID;
          element.label = element.CropDescription;
        });
      },
      error: error => {
        if (error === '') {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.DisplayErrorMessage('Error', error);
        }
      }
  });
  }

  getSSLTypes() {
    this._commonService.getSSLTypes().subscribe({
     next: data => {
        this.sslTypeName = data;
        this.sslTypeName.forEach(element => {
          element.value = element.Id;
          element.label = element.Name;
        });
      },
      error: error => {
        if (error === '') {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.DisplayErrorMessage('Error', error);
        }
      }
  });
  }

  clearSSLType() {
    this.sslData.SeedStorageLocationTypeId = '';
    this.sslData.SeedStorageLocationTypeCode = '';
    this.sslData.SeedStorageLocationTypeName = '';
  }

  getStationsByCropID() {
    this.allStationName = [];
    const cropID = this.sslData.CropID;

    this.allStationName = this.sslStationName.filter(s => s.StationCrops.find(element => element.CropID
      === cropID));
  }

  ShowPeoplePicker() {
    this.existingPeople = [];
    if (this.sslData.SeedStorageLocationKeyContacts) {
      this.sslData.SeedStorageLocationKeyContacts.forEach(lead => {
        this.existingPeople.push(lead.Person);
      });
    }

    const obj = new UserRequestedData();
    obj.ParentComponent = this.parentComponent;
    obj.person = this.existingPeople;
    obj.singleLead = false;

    this.existingPeopleString = JSON.stringify(obj);
    this.displayStationLead = true;
  }

  onaddUserDataClick(data: UserRequestedData): void {
    if (!(data == null && data === undefined) && data.ParentComponent === this.parentComponent) {
      this.displayStationLead = false;
      let PrincipalNames = '';

      this.sslData.SeedStorageLocationKeyContacts = [];
      this.sslData.SeedStorageLocationKeyContactNames = '';
      this.existingPeople = [];
      this.keyContactsLead = [];

      data.person.forEach(pr => {
        this.existingPeople.push(pr);

        if (!this.sslData.SeedStorageLocationKeyContacts.find(s => s.Person.LoginName === pr.LoginName)) {
          this.sslData.SeedStorageLocationKeyContacts.push({
            ID: '',
            PersonID: pr.PersonID, Person: pr, SeedStorageLocation: null, SeedStorageLocationID: this.sslData.SeedStorageLocationID
          });
          this.keyContactsLead.push({ label: pr.DisplayName, value: pr.DisplayName });
        }

        PrincipalNames = PrincipalNames + pr.DisplayName + '; ';
      });

      if (this.keyContactsLead) {
        const x = document.querySelector('#lstCSSLLead > div');
        (x as HTMLElement).style.borderBottom = '1px solid';
        (x as HTMLElement).style.borderBottomColor = '#c0c0c0';
      } else {
        const x = document.querySelector('#lstCSSLLead > div');
        (x as HTMLElement).style.borderBottomColor = '3px solid #a94442';
      }

      this.sslData.SeedStorageLocationKeyContactNames = PrincipalNames;
      this.sslData.SeedStorageLocationKeyContactNames = this.sslData.SeedStorageLocationKeyContactNames.trim();

      if (this.sslData.SeedStorageLocationKeyContactNames.lastIndexOf(';')
        === this.sslData.SeedStorageLocationKeyContactNames.length - 1) {
        this.sslData.SeedStorageLocationKeyContactNames = this.sslData.SeedStorageLocationKeyContactNames
          .substring(0, this.sslData.SeedStorageLocationKeyContactNames.lastIndexOf(';'));
      }
    }
  }

  saveSSL(frm: any) {
    const crop = this.sslCropName.find(s => s.value === this.sslData.CropID);
    if (crop === undefined || crop === null) {
      this.sslData.CropDescription = '';
      this.sslData.CropName = '';
      this.sslData.CropID = '';
    } else {
      this.sslData.CropDescription = crop.CropDescription;
      this.sslData.CropName = crop.CropName;
      this.sslData.CropID = crop.CropID;
    }
    const sslType = this.sslTypeName.find(s => s.value === this.sslData.SeedStorageLocationTypeId);

    if (sslType) {
      this.sslData.SeedStorageLocationTypeId = sslType.Id;
      this.sslData.SeedStorageLocationTypeCode = sslType.Code;
      this.sslData.SeedStorageLocationTypeName = sslType.Name;
    }

    this.sslData.SeedStorageLocationType = sslType;

    if (this.sslData.IsVirtual === null || this.sslData.IsVirtual === undefined) {
      this.sslData.IsVirtual = false;
    }
    this.sslData.Station = this.sslStationName.find(s => s.value === this.sslData.StationId);

    if (this.validateForm(frm)) {
      if (this.comments && this.comments.toString().trim() !== '') {
        const comment = new ApprovalComments();
        comment.Comment = this.comments.toString();
        this.sslData.ApprovalComments = [];
        this.sslData.ApprovalComments.push(comment);
      }
      this.sslData.Name = !this.sslData.Name ? '' : this.sslData.Name;
      this._sslService.saveSSL(JSON.stringify(this.sslData))
        .subscribe({
          next: Result => { 
            if(Result.Status == 'SUCCESS'){
            if (this.sslData.Name) {
              this.sslName.push({
                Id: '',
                Name: this.sslData.Name,
                CropID: this.sslData.CropID,
                Description: this.sslData.Description,
                RequestStatus: this.sslData.RequestStatus,
                label: this.sslData.Name,
                value: ''
              });
            }

            this._appService.sendPendingCount(1);

            this.DisplaySuccessMessage('Success', Result.Message);
            this.reset();

            const dataToBeCloned = localStorage.getItem("ToBeClonedSSL");
            if (dataToBeCloned && dataToBeCloned !== 'undefined') {
              this._confirmation.confirm({
                message: "Would you like to clone again?",
                accept: () => {                  
                  this.setupClone();
                },
                reject: () => {
                  localStorage.removeItem("ToBeClonedSSL");
                }
              });
            }
          }
          else {
            this.DisplayErrorMessage('Error', Result.Message);
            localStorage.removeItem("ToBeClonedSSL");
          } 
          },
          error: error => {
            if (error === '') {
              this.DisplayErrorMessage('Error', Constants.NOSERVICE);
            } 
            else {
              this.DisplayErrorMessage('Error', error);
            }

            localStorage.removeItem("ToBeClonedSSL");
          }
    });
    }
  }

  validateForm(frm: NgForm) {
    if ((this.sslData.Name
      && (this.sslData.Name.trim().length < 2 || this.sslData.Name.trim().length > 4))) {
      this.DisplayWarningMessage('Warning', 'Seed Storage Location Name should be between 2 and 4 characters long...');
      return false;
    }

    if (!this.sslData.CropName || this.sslData.CropName === '') {
      this.DisplayWarningMessage('Warning', 'Crop is required...');
      return false;
    }

    if (this.sslData.Name && this.sslData.Name.toString().trim() !== '') {
      const pCode = this.sslName.find(s => s.Name !== null && s.Name.toString().toLowerCase()
        === this.sslData.Name.toString().toLowerCase()
        && s.CropID === this.sslData.CropID && s.RequestStatus !== RequestStatus.RETIRED);

      if (pCode) {
        this.DisplayWarningMessage('Warning', 'This SSL Name and Crop combo already exists. Please enter a new Name or Crop...');
        return false;
      }
    }

    if (this.sslData.WWSShippingProject && this.sslData.WWSShippingProject.toString().length > 255) {
      this.DisplayWarningMessage('Warning', 'Allowed Max length is 255 characters for WWS Shipping Project...');
      return false;
    }

    return true;
  }

  public DisplayErrorMessage(title: string, message: string) {
    this._commonService.DisplayErrorMessage(title, message);
  }

  public DisplayWarningMessage(title: string, message: string) {
    this._commonService.DisplayWarningMessage(title, message);
  }

  public DisplayInfoMessage(title: string, message: string) {
    this._commonService.DisplayInfoMessage(title, message);
  }

  public DisplaySuccessMessage(title: string, message: string) {
    this._commonService.DisplaySuccessMessage(title, message);
  }

  reset() {
    this.sslData = new SeedStorageLocationModel();
    this.sslData.RequestStatus = RequestStatus.PENDING;
    this.keyContactsLead = [];
    this.existingPeople = [];
    this.keyContactsLead = [];
    if (this.keyContactsLead) {
      const x = document.querySelector('#lstCSSLLead > div');
      (x as HTMLElement).style.borderBottom = '1px solid #ced4da';
      
    }
    this.comments = '';
  }

  clearStation() {
    this.sslData.StationId = '';
  }

  clearCrop() {
    this.sslData.CropID = '';
    this.clearStation();
  }
}