import { Injectable } from '@angular/core';
import {EnvironmentService, UrlConfig} from "../../environments/environment.service";
import {HttpService} from "../http/http.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CreateUserRoleService {
  urlConfig: UrlConfig;

  constructor(private _http: HttpService, private environment: EnvironmentService) {
    this.urlConfig = environment.getEnvironment();
  }

  loadUsers(): Observable<any> {
    return this._http.get(this.urlConfig.users);
  }
}
