<div class="container-fluid" [hidden]="isROVAdmin || isROVSuperUser">
  <div class="col-sm-3">
  </div>

  <div class="col-sm-6">
    <h3>
        <span>
          You don't have access to the
          <b>User Roles</b> screen.
          <br />
          <a id="linkUserUtilityHomeScreen" href="/">Click Here</a> to go to Home Screen.
        </span>
    </h3>
  </div>

  <div class="co-sm-3">
  </div>
</div>

<form id="userRoleForm" [formGroup]="userRoleForm">
  <p-panel id="pnlUserRole">
    <p-header id="pnlHdrUserRole">
      <span>
        Users & Roles
      </span>
    </p-header>

    <div class="grid">
        <div class=" col-3 controlLabel">
          <label for="acUsers">
            Users
          </label>
        </div>
        <div class="col-8">
          <span class="p-fluid">
            <p-autoComplete id="acUsers" formControlName="selectedUsers"
                            [suggestions]="users" optionLabel="label" placeholder="Enter one or more Users"
                            (completeMethod)="filterUsers($event)"
                            [multiple]="true" [showClear]="true"/>
          </span>
        </div>
        <div class=" col-3 controlLabel">
          <label for="acRoles">
            Roles
          </label>
        </div>
        <div class="col-8">
          <span class="p-fluid">
            <p-autoComplete id="acRoles" formControlName="selectedRoles"
                            [suggestions]="roles" optionLabel="label" placeholder="Enter one or more Roles"
                            (completeMethod)="filterRoles($event)"
                            [multiple]="true" [showClear]="true">
            </p-autoComplete>
          </span>
        </div>
      </div>
  </p-panel>
</form>