import { UserResponsibilityStationAssoc } from './userResponsibilityStationAssoc';
import { StationCrops } from './stationCrops';
import { ApprovalComments } from './approvalComments';
import { Person } from './person';
import { StationTypeModel } from './stationTypeModel';
import { RegionModel } from './regionModel';

export class StationResultModel {
    public StationID: string;
    public StationName: string;
    public StationCode: string;
    public UserResponsibilityStationAssocNames: string;
    public UserResponsibilityShiptoStationAssocNames: string;
    public UserResponsibilityStationSafetyContactAssocNames: string;
    public StationCrops: StationCrops[] = [];
    public UserResponsibilityStationAssoc: UserResponsibilityStationAssoc[] = [];
    public UserResponsibilityShiptoStationAssoc: UserResponsibilityStationAssoc[] = [];
    public UserResponsibilityStationSafetyContactAssoc: UserResponsibilityStationAssoc[] = [];
    public ServerNames: string;
    public CropNames: string;
    public Description: string;
    public SSL: string;
    public PRISMLEID: string;
    public ServerAddress: string;
    public R3SiteCode: string;
    public RequestStatus: string;
    public Address: string;
    public CityId: number|null;
    public City: string|null;
    //public LocalCityId: number;
    //public LocalCity: string;

    public CountyId: number;
    public County: string;
    //public LocalCountyId: number;
    //public LocalCounty: string;

    public StateId: number|null;
    public State: string|null;
    //public LocalStateId: number;
    //public LocalState: string;

    public CountryId: number|null;
    public Country: string|null;
    //public LocalCountryId: number;
    //public LocalCountry: string;
    public CountryCode: string;
    public Longitude: string;
    public Latitude: string;
    public CreatedBy: Person;
    public CreatedByUserName: string;
    public ApprovalComments: ApprovalComments[] = [];
    public CreatedOn: Date;
    public ModifiedByUserName: string;
    public ModifiedBy: Person;
    public ModifiedOn: Date;
    public ApprovedByUserName: string;
    public ApprovedBy: Person;
    public ApprovedOn: Date;
    public RetiredByUserName: string;
    public RetiredBy: Person;
    public RetiredOn: Date;
    public BaseStationID: string;
    public StationTypeID: string;
    public StationType: StationTypeModel;
    public RegionID: string;
    public Region: RegionModel;

    public TechnologyTierTypeId: string;
    public TechnologyTierName: string;
    public CorporateITCode: string;
    public Comment: string;
    public GeographicLocale: string|null;
    public GAAResponseJson: string;
    public Level3Type: string;
    public ConsolidationCropNames: string;
}
