import {Component, OnInit} from '@angular/core';
import {PanelModule} from "primeng/panel";
import {ChipsModule} from "primeng/chips";
import {FormBuilder, FormControl, ReactiveFormsModule} from "@angular/forms";
import {RadUser} from "../shared/radUser";
import {CommonService} from "../shared/common.service";
import {SharedService} from "../services/shared.service";
import {Constants} from "../shared";
import {UserClaim} from "../shared/userClaim";
import {CreateUserRoleService} from "./create-user-role.service";
import {AutoCompleteCompleteEvent, AutoCompleteModule} from "primeng/autocomplete";
import {Option} from "../model/option";
import {NgClass} from "@angular/common";
import {ButtonDirective} from "primeng/button";

@Component({
  selector: 'app-create-user-role',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    PanelModule,
    ChipsModule,
    AutoCompleteModule,
    NgClass,
    ButtonDirective
  ],
  templateUrl: './create-user-role.component.html',
  styleUrl: './create-user-role.component.scss'
})
export class CreateUserRoleComponent implements OnInit {
  public userName: string;
  public isROVAdmin = false;
  public isROVUser = false;
  public isROVSuperUser = false;
  public loading = false;
  public loadingUsers = false;

  public allUsers: RadUser[] = [];
  public users: RadUser[] = [];
  public existingRoles: Option[];
  public roles: Option[] = [];

  public userRoleForm = this.fb.nonNullable.group({
    selectedUsers: new FormControl(),
    selectedRoles: new FormControl()
  })

  constructor(private fb: FormBuilder, private _commonService: CommonService,
              private _shareService: SharedService, private _createUserRoleService: CreateUserRoleService) {
  }

  ngOnInit() {
    this.getLoginInfo();
    this.loadUsers();
  }


  getLoginInfo() {
    let localRoles = this._shareService.getSessionStorageValue(Constants.UIUSERROLE);
    if (localRoles != '' && localRoles !== null) {
      const userClaim = JSON.parse(localRoles || '{}') as UserClaim;
      this.userName = userClaim.username;
      this.isROVAdmin = userClaim.isROVAdmin;
      this.isROVUser = userClaim.isROVUser;
      this.isROVSuperUser = userClaim.isROVSuperUser;
    }
  }

  loadUsers() {
    this.loading = true;
    this.loadingUsers = true;

    this._createUserRoleService.loadUsers().subscribe({
      next: data => {
        if (data && data.length > 0) {
          this.setUserData(data)
          this.populateExistingRoles()
        }
      },
      error: error => {
        if (error === '') {
          this.displayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.displayErrorMessage('Error', error);
        }
        this.loadingUsers = false;
      },
      complete: () => {
        this.loadingUsers = false;
        this.loading = true;
      }
    })
  }

  setUserData(input: RadUser[]) {
    this.allUsers = []
    input.forEach(user => {
      user.label = user.DisplayName;
      user.value = user.Id;
    })
    this.allUsers = input;
  }

  populateExistingRoles() {
    this.existingRoles = [];
    this.allUsers.forEach(user => {
      if (user.Roles !== '') {
        let roles: string[] = user.Roles.split(",");
        roles.forEach(item => {
          let role: Option = {label: item, value: item};
          if (this.existingRoles.some(s => s.value === role.value)) {
            return;
          }
          this.existingRoles.push(role);
        })
      }
    })

    this.existingRoles = this.existingRoles.sort();
  }

  filterUsers(event: AutoCompleteCompleteEvent) {
    this.users = this.findFilteredItems(this.allUsers, event.query);
  }

  filterRoles(event: AutoCompleteCompleteEvent) {
    this.roles = this.findFilteredItems(this.existingRoles, event.query);
  }

  findFilteredItems(allItems: any[], query: string): any[] {
    let filtered: any[] = [];

    for (let i = 0; i < allItems.length; i++) {
      let item = allItems[i];
      if (item.label.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(item)
      }
    }

    return filtered;
  }

  reset() {
    this.userRoleForm.controls.selectedRoles.setValue([]);
    this.userRoleForm.controls.selectedUsers.setValue([]);
  }

  save() {

  }

  displayErrorMessage(title: string, message: string) {
    this._commonService.DisplayErrorMessage(title, message);
  }

  displayWarningMessage(title: string, message: string) {
    this._commonService.DisplayWarningMessage(title, message);
  }

  displayInfoMessage(title: string, message: string) {
    this._commonService.DisplayInfoMessage(title, message);
  }

  displaySuccessMessage(title: string, message: string) {
    this._commonService.DisplaySuccessMessage(title, message);
  }
}

