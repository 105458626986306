import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { RegionService } from '.././region/region.service';
import { CropModel } from './../shared/cropModel';
import { RegionModel } from './../shared/regionModel';
import { Person } from '.././shared/person';
import { RegionResultModel } from '.././shared/regionResultModel';
import { ApprovalComments } from '.././shared/approvalComments';
import { UserRequestedData } from '.././shared/userRequestedData';
import { Message } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { RegionForm } from './../shared/regionFormControls';
import { Constants, RequestStatus } from '../shared';
import { CommonService } from '../shared/common.service';
import { AppService } from '../app.service';


@Component({
  selector: 'app-createregion',
  templateUrl: './createregion.component.html',
  styleUrls: ['./createregion.component.scss']
})
export class CreateRegionComponent implements OnInit, OnChanges {

  msgs: Message[] = [];
  public displayRegionLead = false;
  public existingPeople: Person[] = [];
  public regionLead: SelectItem[] = [];
  public existingPeopleString = '';
  public regionCropName: CropModel[] = [];
  public regionName: RegionModel[] = [];
  public regionCodes: RegionModel[] = [];
  public regionData: RegionResultModel = new RegionResultModel();
  public parentComponent = 'CreateRegion';
  public createRegionFormGroup: FormGroup;
  public comments = '';
  public regionFormGroup = new RegionForm(
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    [],
    '',
    '',
    [],
    new Person(),
    '',
    new Date(),
    '',
    new Person(),
    new Date(),
    '',
    new Person(),
    new Date(),
    '',
    new Person(),
    new Date(),
    ''
  );

  @Input()
  public selectedTabIndex: number;

  constructor(private _commonService: CommonService, private _regionService: RegionService,
    private _appService: AppService, private _fb: FormBuilder) {
  }

  ngOnInit() {
    this.buildForm();
    this.regionData.RequestStatus = RequestStatus.PENDING;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selectedTabIndex === 4) {
      this.bindRegionDropdownLists();
    }
  }

  buildForm(): void {
    this.createRegionFormGroup = this._fb.group({
      'RegionName': [this.regionFormGroup.RegionName],
      'RegionCode': [this.regionFormGroup.RegionCode],
      'RegionLeadNames': [this.regionFormGroup.RegionLeadNames],
      'Description': [this.regionFormGroup.Description],
      'Color': [this.regionFormGroup.Color],
      'CropID': [this.regionFormGroup.CropID, Validators.required],
      'CropName': [this.regionFormGroup.CropName],
      'CropDescription': [this.regionFormGroup.CropDescription],
      'RequestStatus': [{ value: this.regionFormGroup.RequestStatus, disabled: true }],
      'PRISM_AOI_ID': [this.regionFormGroup.PRISM_AOI_ID],
      'Comment': [this.regionFormGroup.ApprovalComments]
    });
  }

  bindRegionDropdownLists() {
    this._regionService.bindRegionDropdownLists().subscribe({
      next: data => {
        if (data.RegionCodes && data.RegionCodes.length > 0) {
          this.regionCodes = [];

          data.RegionCodes.forEach((item: { Id: any; Code: string; Name: string; CropID: any; Description: any; RequestStatus: any; }) => {
            this.regionCodes.push({
              Id: item.Id,
              Code: item.Code, Name: item.Name,
              CropID: item.CropID,
              Description: item.Description,
              RequestStatus: item.RequestStatus,
              label: item.Name + ' - ' + item.Code,
              value: item.Id
            });
          });
        }

        if (data.Crops && data.Crops.length > 0) {
          this.regionCropName = data.Crops;
          this.regionCropName.forEach(element => {
            element.value = element.CropID;
            element.label = element.CropDescription;
          });
        }
      },
      error: error => {
        if (error === '') {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.DisplayErrorMessage('Error', error);
        }
      }
  });
  }

  ShowPeoplePicker() {
    this.existingPeople = [];
    if (this.regionData.RegionLeads) {
      this.regionData.RegionLeads.forEach(lead => {
        this.existingPeople.push(lead.Person);
      });
    }

    const obj = new UserRequestedData();
    obj.ParentComponent = this.parentComponent;
    obj.person = this.existingPeople;
    obj.singleLead = true;
    this.existingPeopleString = JSON.stringify(obj);
    this.displayRegionLead = true;
  }

  onaddUserDataClick(data: UserRequestedData): void {
    if (!(data == null && data === undefined) && data.ParentComponent === this.parentComponent) {
      this.displayRegionLead = false;
      let RegionLeadNames = '';

      this.regionData.RegionLeads = [];
      this.regionData.RegionLeadNames = '';
      this.existingPeople = [];
      this.regionLead = [];

      data.person.forEach(pr => {
        this.existingPeople.push(pr);

        if (!this.regionData.RegionLeads.find(s => s.Person.LoginName === pr.LoginName)) {
          this.regionData.RegionLeads.push({
            ID: '',
            PersonID: pr.PersonID, Person: pr, Region: null, RegionID: this.regionData.RegionID
          });
          this.regionLead.push({ label: pr.DisplayName, value: pr.DisplayName });
        }

        RegionLeadNames = RegionLeadNames + pr.DisplayName + '; ';
      });

      this.regionData.RegionLeadNames = RegionLeadNames;
      this.regionData.RegionLeadNames = this.regionData.RegionLeadNames.trim();

      if (this.regionData.RegionLeadNames.lastIndexOf(';')
        === this.regionData.RegionLeadNames.length - 1) {
        this.regionData.RegionLeadNames = this.regionData.RegionLeadNames
          .substring(0, this.regionData.RegionLeadNames.lastIndexOf(';'));
      }
    }
  }

  saveRegion(frm:any) {
    const crop = this.regionCropName.find(s => s.value === this.regionData.CropID);
    if (crop) {
      this.regionData.CropDescription = crop.CropDescription;
      this.regionData.CropName = crop.CropName;
      this.regionData.CropID = crop.CropID;
    }

    if (this.validateForm(frm)) {
      if (this.comments
        && this.comments.toString().trim() !== '') {
        const comment = new ApprovalComments();
        comment.Comment = this.comments.toString();
        this.regionData.ApprovalComments = [];
        this.regionData.ApprovalComments.push(comment);
      }
      this._regionService.saveRegion(JSON.stringify(this.regionData))
        .subscribe({
          next: Result => { 
            if(Result.Status == 'SUCCESS'){
            this.regionCodes.push({
              Id: '',
              Code: this.regionData.RegionCode,
              Name: this.regionData.RegionName,
              Description: this.regionData.Description,
              CropID: this.regionData.CropID,
              RequestStatus: this.regionData.RequestStatus,
              label: this.regionData.RegionName + ' - ' + this.regionData.RegionCode,
              value: ''
            });

            this._appService.sendPendingCount(1);

            this.DisplaySuccessMessage('Success', Result.Message);
            this.reset(); 
          } 
          else {
            this.DisplayErrorMessage('Error', Result.Message);
          } 
          },
         error: error => {
            if (error === '') {
              this.DisplayErrorMessage('Error', Constants.NOSERVICE);
            } else {
              this.DisplayErrorMessage('Error', error);
            }
          }
    });
    }
  }

  validateForm(frm: NgForm) {
    if (!this.regionData.RegionName || this.regionData.RegionName.trim() === '') {
      this.DisplayWarningMessage('Warning', 'RCZ Name is required...');
      return false;
    }

    if ((!this.regionData.RegionCode
      || (this.regionData.RegionCode
        && this.regionData.RegionCode.trim() === ''))) {
      this.DisplayWarningMessage('Warning', 'RCZ Code is required...');
      return false;
    }

    if ((this.regionData.RegionCode
      && (this.regionData.RegionCode.trim().length < 2
        || this.regionData.RegionCode.trim().length > 4))) {
      this.DisplayWarningMessage('Warning', 'RCZ Code should be 2 to 4 characters long...');
      return false;
    }

    if (!this.regionData.CropName || this.regionData.CropName === '') {
      this.DisplayWarningMessage('Warning', 'Crop is required...');
      return false;
    }

    if (this.regionData.RegionCode && this.regionData.RegionCode.toString().trim() !== '') {
      const sCode = this.regionCodes.find(s => s.Code.toString().toLowerCase()
        === this.regionData.RegionCode.toString().toLowerCase() && s.CropID === this.regionData.CropID
        && s.RequestStatus !== RequestStatus.RETIRED);

      if (sCode) {
        this.DisplayWarningMessage('Warning', 'This RCZ Code and Crop combo already exists. Please enter a new Code or Crop...');
        return false;
      }
    }
    if (this.regionData.Color
      && (this.regionData.Color.toString().indexOf('-') >= 0
        || this.regionData.Color.toString().indexOf('.') >= 0)) {
      this.DisplayWarningMessage('Warning', 'Decimal or negative value is not allowed for Color...');
      return false;
    }

    if (this.regionData.Color
      && this.regionData.Color.toString().trim().length > 9) {
      this.DisplayWarningMessage('Warning', 'Allowed Max length is 9 characters for Color...');
      return false;
    }

    return true;
  }

  public DisplayErrorMessage(title: string, message: string) {
    this._commonService.DisplayErrorMessage(title, message);
  }

  public DisplayWarningMessage(title: string, message: string) {
    this._commonService.DisplayWarningMessage(title, message);
  }

  public DisplayInfoMessage(title: string, message: string) {
    this._commonService.DisplayInfoMessage(title, message);
  }

  public DisplaySuccessMessage(title: string, message: string) {
    this._commonService.DisplaySuccessMessage(title, message);
  }

  reset() {
    this.regionData = new RegionResultModel();
    this.regionLead = [];
    this.existingPeople = [];
    this.comments = '';
  }

  clearCrop() {
    this.regionData.CropID = '';
  }
}