<div class="container-fluid" [hidden]="isROVAdmin || isROVSuperUser">
    <div class="col-sm-3">        
    </div>

    <div class="col-sm-6">
      <h3>
        <span>
          You don't have access to the
          <b>User Associations</b> screen.
          <br />
          <a id="linkUserUtilityHomeScreen" href="/">Click Here</a> to go to Home Screen.
        </span>
      </h3>
    </div>

    <div class="co-sm-3">        
    </div>
  </div>

<form id="frmUserAssoc" [formGroup]="userAssocForm">
    <p-panel id="pnlUserAssoc">
        <p-header id="pnlHdrUserAssoc">
          <span>
            User Associations
          </span>
        </p-header>

        <div class="grid">
            <div class="controlLabel col-3">
                <label id="lblUsers">Users</label>
            </div>
            <div class="col-8">
                <span class="p-fluid">
                    <p-autoComplete id="acUsers" formControlName="selectedUsers"
                        [suggestions]="users" optionLabel="label" placeholder="Enter one or more Users"
                        (completeMethod)="filterUsers($event)"
                        display="chip" [multiple]="true" [showClear]="true"/>
                </span>
                <!-- <p-multiSelect id="msUsers" formControlName="selectedUsers"
                        [options]="users" optionLabel="label" placeholder="Enter one or more Users"
                        display="chip" show [showClear]="true" [loading]="loadingUsers" [maxSelectedLabels]="1000"/> -->
            </div>
            <div class="controlLabel col-3">
                <label id="lblCrops">Crops</label>
            </div>
            <div class="col-8">
                <span class="p-fluid">
                    <p-autoComplete id="acCrops" formControlName="selectedCrops"
                        [suggestions]="crops" optionLabel="label" placeholder="Enter one or more Crops"
                        (completeMethod)="filterCrops($event)"
                        display="chip" [multiple]="true" [dropdown]="true" [showClear]="true"/>
                </span>
                <!-- <p-multiSelect id="msCrops" formControlName="selectedCrops"
                        [options]="crops" optionLabel="label" placeholder="Enter one or more Crops"
                        display="chip" show [showClear]="true" [loading]="loading" [maxSelectedLabels]="1000"/> -->
            </div>
            <div class="controlLabel col-3">
                <label id="lblStations">Stations</label>
            </div>
            <div class="col-8">
                <span class="p-fluid">
                    <p-multiSelect id="msStations" formControlName="selectedStations"
                            [options]="stations" optionLabel="label" placeholder="Enter one or more Stations"
                            display="chip" show [showClear]="true" [loading]="loading" [maxSelectedLabels]="1000"/>
                </span>
            </div>
            <div class="controlLabel col-3">
                <label id="lblPrograms">Programs</label>
            </div>
            <div class="col-8">
                <p-multiSelect id="msPrograms" formControlName="selectedPrograms"
                        [options]="programs" optionLabel="label" placeholder="Enter one or more Programs"
                        display="chip" show [showClear]="true" [loading]="loading" [maxSelectedLabels]="1000"/>
            </div>
        </div>
    </p-panel>
</form>