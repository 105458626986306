import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';

import { Constants } from '../app/shared';
import { UserClaim, GraphMe} from "./shared/userClaim";
import { SharedService } from '../app/services/shared.service';
import { ApplicationSettingsService } from './application-settings.service';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppService } from './app.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Settings } from './settings';
import { ApplicationSettings } from './application-settings.service';
import { Message } from 'primeng/api';
import { SpinnerService } from './services/spinner.service';
import { CommonService } from './shared/common.service';
import { ConfirmationService } from "primeng/api";

/**
 * TODO: Enable app insights (see appropriate README.md section)
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'manageR';
  IsActive: boolean = false;
  isLoggedIn = false;
  public versionNumber = '';
  public apiVersionNumber = '';
  public currentEnv = '';
  public isROVAdmin = false;
  public isROVPublishedResourceFileUser = false;
  public isROVUser = false;
  public isROVSuperUser = false;
  public isGluuUp = true;
  public msgs: Message[] = [];
  public historyCountString = 'Recent History';
  public pendingRequests = 0;

  private userClaim: UserClaim;
  public settings: Settings;
  public claims: UserClaim;
  public username: string;
  url: string = '';
  isIframe = false;
  sampleIdPattern = /^\/([a-zA-Z]+)\/([0-9]+)$/g;
  sampleIdRoute: string = '';
  private readonly _destroying$ = new Subject<void>();
  applicationsettings:ApplicationSettings;

  isActiveAccount: boolean = false;

  get needToRedirect(): boolean {
    return this.claims === null || this.claims === undefined;
  }

  constructor(private router: Router, private authService: MsalService, 
    private sharedservice: SharedService, private _commonservice: CommonService,
    private _appService: AppService, private msalBroadcastService: MsalBroadcastService, 
    applicationSettingsService: ApplicationSettingsService, public spinnerService: SpinnerService, 
    private _confirmation: ConfirmationService) {
    this.currentEnv = applicationSettingsService.settings.environment.name;

    this.applicationsettings = applicationSettingsService.settings;
    const angularPlugin: any = new AngularPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: applicationSettingsService.settings.appInsights.instrumentationKey,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: router }
        }
      }
    });

    appInsights.loadAppInsights();
  }

  ngOnInit(): void {
    this.checkAndSetActiveAccount();


    this.isIframe = window !== window.parent && !window.opener;
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        
      })
  }

  public cleanCache() {
    const resNav = document.querySelectorAll('#res-nav');
    if(resNav && resNav.length > 0) {
    setTimeout(function () {
      (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(1) > li > div > ul > res-nav-item:nth-child(1) > li')
      [0] as HTMLElement).style.backgroundColor = 'white';
      (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(1) > li > div > ul > res-nav-item:nth-child(2) > li')
      [0] as HTMLElement).style.backgroundColor = 'white';
      (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(1) > li > div > ul > res-nav-item:nth-child(3) > li')
      [0] as HTMLElement).style.backgroundColor = 'white';
      (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(2) > li > div > ul > res-nav-item:nth-child(1) > li')
      [0] as HTMLElement).style.backgroundColor = 'white';
      (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(2) > li > div > ul > res-nav-item:nth-child(2) > li')
      [0] as HTMLElement).style.backgroundColor = 'white';
      (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(2) > li > div > ul > res-nav-item:nth-child(3) > li')
      [0] as HTMLElement).style.backgroundColor = 'white';
      (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(2) > li > div > ul > res-nav-item:nth-child(4) > li')
      [0] as HTMLElement).style.backgroundColor = 'grey';
      (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(3) > li > div > ul > res-nav-item:nth-child(1) > li')
      [0] as HTMLElement).style.backgroundColor = 'white';
      (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(3) > li > div > ul > res-nav-item:nth-child(2) > li')
      [0] as HTMLElement).style.backgroundColor = 'white';
      (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(3) > li > div > ul > res-nav-item:nth-child(3) > li')
      [0] as HTMLElement).style.backgroundColor = 'white';
      (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(3) > li > div > ul > res-nav-item:nth-child(4) > li')
      [0] as HTMLElement).style.backgroundColor = 'white';
      (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(4) > li > div > ul > res-nav-item:nth-child(1) > li')
      [0] as HTMLElement).style.backgroundColor = 'white';
      (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(4) > li > div > ul > res-nav-item:nth-child(1) > li')
      [0] as HTMLElement).style.backgroundColor = 'white';
      (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(4) > li > div > ul > res-nav-item:nth-child(2) > li')
      [0] as HTMLElement).style.backgroundColor = 'white';
      (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(4) > li > div > ul > res-nav-item:nth-child(3) > li')
      [0] as HTMLElement).style.backgroundColor = 'white';
      (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(4) > li > div > ul > res-nav-item:nth-child(4) > li')
      [0] as HTMLElement).style.backgroundColor = 'white';
      (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(4) > li > div > ul > res-nav-item:nth-child(5) > li')
      [0] as HTMLElement).style.backgroundColor = 'white';
      (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(4) > li > div > ul > res-nav-item:nth-child(6) > li')
      [0] as HTMLElement).style.backgroundColor = 'white';
      (document.querySelectorAll('#res-nav > ul > res-nav-group:nth-child(4) > li > div > ul > res-nav-item:nth-child(7) > li')
      [0] as HTMLElement).style.backgroundColor = 'white';
    }, 100);
  }

    this._appService.cleanCache()
      .subscribe({
        next: data => {
          if (data) {
            this._commonservice.DisplaySuccessMessage('Success', 'Cache cleaned up successfully...');

            setTimeout(function () {
              document.location.href = '/';
            }, 2000);
          } else {
            this._commonservice.DisplayErrorMessage('Error', 'Error while cleaning up cache...');
          }
        },
       error: error => {
          if (error === '') {
            this._commonservice.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this._commonservice.DisplayErrorMessage('Error', error);
          }
        }
  });
  }

  checkAndSetActiveAccount() {
    let user: any;
    user = this.authService.instance.getAllAccounts()[0];

    // To get Login details and role
    if (user) {
      this.getRecentHistoryCount();
      this._appService.version().subscribe(i => this.versionNumber = i);
      this._appService.getAPIVersion().subscribe(i => this.apiVersionNumber = i);
      this._appService.getPendingCount().subscribe(count => {
      this.pendingRequests = this.pendingRequests + count;
        this.historyCountString = `Recent History - ${(this.pendingRequests)}`;
      });

      this.claims = new UserClaim();
      this.claims.email = user.username;
      this.claims.username = user.username;
      this.claims.name = user.name;

      this.isLoggedIn = true;

      this.sharedservice.setSessionStorageValue(Constants.UIUSERROLE,"");

      this._appService.getUserClaim().subscribe({
        next: c => {
          this.isROVUser = this.claims.isROVUser = c.IsROVUser;
          this.isROVAdmin = this.claims.isROVAdmin = c.IsROVAdmin;
          this.isROVPublishedResourceFileUser = this.claims.isROVPublishedResourceFileUser = c.IsROVPublishedResourceFileUser;
          this.isROVSuperUser = this.claims.isROVSuperUser = c.IsROVSuperUser;
          
          this.sharedservice.setSessionStorageValue(Constants.UIUSERROLE, JSON.stringify(this.claims));
          this.checkForRoles(this.claims);
      
          if (this.sharedservice.getSessionStorageValue(Constants.UIUSERROLE) == null) {
            this.router.navigate([Constants.Nav_unauthorized]);
          }
        },
        error: error => { this.router.navigate([Constants.Nav_unauthorized]); }
      });
    }
    else {
      this.router.navigate([Constants.Nav_unauthorized]);
    } 
  }

  checkForRoles(claims: UserClaim): void {
    if(this.applicationsettings.apiUrl == 'api') {
      this.router.navigate([Constants.Nav_Home], { queryParamsHandling: 'preserve' });
    }
    else {
      this.isLoggedIn = true;
      if ((claims.isROVAdmin || claims.isROVUser || claims.isROVSuperUser || claims.isROVPublishedResourceFileUser)) {
        this.router.navigate([Constants.Nav_Home], { queryParamsHandling: 'preserve' });
      }
      else {
        this.router.navigate([Constants.Nav_unauthorized]);
      }
    }
  }

  public login() {
    this.authService.loginRedirect().subscribe({
      next: () => {},
      error: (error) => console.log(error)
    });
  }

  navigateTo(url: string) {
    this.router.navigate(['/' + url]);
  }

  public annualResourceRefresh(){
    this._confirmation.confirm({
      message: "This action will replicate the yearly records associated with Resource File. This operation cannot be reverted. Are you sure you want to do this?",
      accept: () => {
        this._appService.migrateCRAInfo().subscribe({
          next: (response: boolean) => {
            if(response){
            this._commonservice.DisplaySuccessMessage('Success', Constants.CRAINFODATAINSERTED);
            }
            else{
              this._commonservice.DisplayInfoMessage('Info', Constants.CRAINFODATAEXISTS);
            }
          },
          error: (error: any) => {
            this._commonservice.DisplayErrorMessage('Error',error);
          },
          complete: () => { }
        });
      }
    });
  }

  public getRecentHistoryCount() {
    this._appService.getRecentHistoryCount().subscribe({
        next: pendingRequests => {
          this.pendingRequests = pendingRequests;
          if (pendingRequests) {
            this.historyCountString = `Recent History - ${pendingRequests}`;
          } else {
            this.historyCountString = 'Recent History';
          }
        },
        error: error => {
          if (error === '') {
            this._commonservice.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this._commonservice.DisplayErrorMessage('Error', error);
          }
          this.historyCountString = 'Recent History';
        }
      });
  }
}