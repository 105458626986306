import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ApprovalComments, Constants, RegionModel, RequestStatus} from "../shared";
import {FormBuilder, FormControl, ReactiveFormsModule} from "@angular/forms";
import {CommonService} from "../shared/common.service";
import {CommercialMarketService} from "../commercialMarket/commercialmarket.service";
import {AppService} from "../app.service";
import {PanelModule} from "primeng/panel";
import {InputTextModule} from "primeng/inputtext";
import {DropdownModule} from "primeng/dropdown";
import {InputTextareaModule} from "primeng/inputtextarea";
import {ButtonDirective} from "primeng/button";
import {Option} from "../model/option";
import {CommercialMarketZoneResultModel} from "../shared/commercialMarketZoneResultModel";
import {SeedCommercialMarketCombo} from "../shared/seedCommercialMarketCombo";

@Component({
    selector: 'app-create-commercial-market-zone',
    standalone: true,
    imports: [
        PanelModule,
        ReactiveFormsModule,
        InputTextModule,
        DropdownModule,
        InputTextareaModule,
        ButtonDirective
    ],
    templateUrl: './create-commercial-market-zone.component.html',
    styleUrl: './create-commercial-market-zone.component.scss'
})
export class CreateCommercialMarketZoneComponent implements OnInit, OnChanges {
    public allRegions: RegionModel[] = [];
    public regOptions: Option[] = [];
    public parentComponent: string = "CreateCommercialMarket";
    public commercialMarketData: CommercialMarketZoneResultModel = new CommercialMarketZoneResultModel();
    public commercialMarketCodes: SeedCommercialMarketCombo[] = [];

    public cmzForm = this.fb.nonNullable.group({
        commercialMarketZoneName: new FormControl(),
        commercialMarketZoneCode: new FormControl(),
        description: new FormControl(),
        regionId: new FormControl(),
        approvalComment: new FormControl()
    })

    @Input() public selectedTabIndex: number;

    constructor(private fb: FormBuilder,
                private _commonService: CommonService,
                private _commercialMarketService: CommercialMarketService,
                private _appService: AppService) {
    }

    ngOnInit(): void {
        this.createForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.selectedTabIndex === 7) {
            this.bindCommercialMarketDropDownLists();
        }
    }

    createForm(): void {

    }

    bindCommercialMarketDropDownLists(): void {
        this._commercialMarketService.bindCommercialMarketDropdownLists().subscribe({
            next: data => {
                if (data.CommercialMarketCodes && data.CommercialMarketCodes.length > 0) {
                    this.commercialMarketCodes = [];

                    data.CommercialMarketCodes.forEach((item: {
                        Id: any;
                        Code: string;
                        Name: string;
                        CropID: any;
                        RequestStatus: any;
                    }) => {
                        this.commercialMarketCodes.push({
                            Id: item.Id,
                            Code: item.Code, Name: item.Name,
                            RequestStatus: item.RequestStatus,
                            label: item.Code + ' - ' + item.Name,
                            value: item.Id
                        });
                    });
                }

                if (data.RegionCodes && data.RegionCodes.length > 0) {
                    this.regOptions = [];
                    this.allRegions = data.RegionCodes;
                    this.allRegions.forEach(element => {
                        element.value = element.Id;
                        element.label = element.Code;
                    });

                    this.allRegions.forEach(reg => {
                        if (!this.regOptions.some(r => r.label == reg.Code)) {
                            this.regOptions.push({label: reg.label, value: reg.value});
                        }
                    });
                }
            },
            error: error => {
                if (error === '') {
                    this.displayErrorMessage('Error', Constants.NOSERVICE);
                } else {
                    this.displayErrorMessage('Error', error);
                }
            }
        })
    }


    validateForm(): boolean {
        if (!this.cmzForm.controls.commercialMarketZoneName ||
            this.cmzForm.controls.commercialMarketZoneName.value.trim() === '') {
            this.displayWarningMessage('Warning', 'Commercial Market Name is required...');
            return false;
        }

        if ((!this.cmzForm.controls.commercialMarketZoneCode.value ||
            (this.cmzForm.controls.commercialMarketZoneCode.value &&
                this.cmzForm.controls.commercialMarketZoneCode.value.trim() === ''))) {
            this.displayWarningMessage('Warning', 'Commercial Market Code is required...');
        }

        if ((this.cmzForm.controls.commercialMarketZoneCode.value
            && (this.cmzForm.controls.commercialMarketZoneCode.value.trim().length < 2
                || this.cmzForm.controls.commercialMarketZoneCode.value.trim().length > 4))) {
            this.displayWarningMessage('Warning', 'Commercial Market Code should be between 2 and 4 characters long...');
            return false;
        }

        const cmCode = this.commercialMarketCodes.find(s => {
            const code = s.Code
                ? s.Code.toString().toLowerCase()
                : '';
            const marketCode = this.commercialMarketData.CommercialMarketZoneCode
                ? this.commercialMarketData.CommercialMarketZoneCode.toString().toLowerCase()
                : '';

            return code === marketCode && s.RequestStatus !== RequestStatus.RETIRED;
        })

        if (cmCode) {
            this.displayWarningMessage('Warning', 'This Commercial Market Code and Crop combo already exists. Please enter a new Code or Crop...');
            return false;
        }

        return true;
    }

    save(): void {
        const regionId = this.cmzForm.controls.regionId.value.value;
        const region = this.allRegions.find(r => r.Id === regionId);
        if (region !== undefined) {
            this.commercialMarketData.Region = region;
            this.commercialMarketData.RegionCode = region.Code;
            this.commercialMarketData.RegionID = region.Id;
        }
        this.commercialMarketData.CommercialMarketZoneCode = this.cmzForm.controls.commercialMarketZoneCode.value;
        this.commercialMarketData.CommercialMarketZoneName = this.cmzForm.controls.commercialMarketZoneName.value;
        this.commercialMarketData.Description = this.cmzForm.controls.description.value;
        this.commercialMarketData.ApprovalComments = this.cmzForm.controls.approvalComment.value;
        this.commercialMarketData.RequestStatus = RequestStatus.PENDING;

        if (this.validateForm()) {
            if (this.cmzForm.controls.approvalComment.value && this.cmzForm.controls.approvalComment.value !== '') {
                const comment = new ApprovalComments();
                comment.Comment = this.cmzForm.controls.approvalComment.value.toString();
                this.commercialMarketData.ApprovalComments = [];
                this.commercialMarketData.ApprovalComments.push(comment);
            }

            this._commercialMarketService.saveCommercialMarket(JSON.stringify(this.commercialMarketData))
                .subscribe({
                    next: result => {
                        if (result.Status == 'SUCCESS') {
                            this.commercialMarketCodes.push({
                                Id: '',
                                Code: this.commercialMarketData.CommercialMarketZoneCode,
                                Name: this.commercialMarketData.CommercialMarketZoneName,
                                RequestStatus: this.commercialMarketData.RequestStatus,
                                label: this.commercialMarketData.CommercialMarketZoneCode + ' - ' + this.commercialMarketData.CommercialMarketZoneName,
                                value: ''
                            });

                            this._appService.sendPendingCount(1);

                            this.displaySuccessMessage('Success', result.Message);
                            this.reset();
                        } else {
                            this.displayErrorMessage('Error', result.Message);
                        }
                    },
                    error: error => {
                        if (error === '') {
                            this.displayErrorMessage('Error', Constants.NOSERVICE);
                        } else {
                            this.displayErrorMessage('Error', error);
                        }
                    }
                });
        }
    }

    reset(): void {
        this.commercialMarketData = new CommercialMarketZoneResultModel();
        this.commercialMarketData.RequestStatus = RequestStatus.PENDING;
        this.cmzForm.reset();
    }


    displayErrorMessage(title: string, message: string): void {
        this._commonService.DisplayErrorMessage(title, message);
    }

    displayWarningMessage(title: string, message: string) {
        this._commonService.DisplayWarningMessage(title, message);
    }

    displaySuccessMessage(title: string, message: string) {
        this._commonService.DisplaySuccessMessage(title, message);
    }
}
