<form id="frmCommercialMarketZone"
      [formGroup]="cmzForm">
    <p-panel id="pnl001" >
        <p-header id="pnl001Hdr">
      <span>
        Commercial Market Zone Details
      </span>
        </p-header>
        <div class="grid">
            <div class="col-6">
                <div class="grid">
                    <div class="col-3 controlLabel">
                        <label for="txtCmzName">
                            CMZ Name
                        </label>
                    </div>
                    <div class="col-9">
                        <input id="txtCmzName"
                               name="commercialMarketZoneName"
                               pInputText required autofocus
                               formControlName="commercialMarketZoneName"
                               minlength="1"
                               maxlength="100"/>
                    </div>

                    <div class="col-3 controlLabel">
                        <label for="txtCCMCode">
                            CMZ Code
                        </label>
                    </div>
                    <div class="col-9">
                        <input id="txtCCMCode"
                               name="commercialMarketZoneCode"
                               pInputText required
                               formControlName="commercialMarketZoneCode"
                               minlength="2"
                               maxlength="100"/>
                    </div>

                    <div class="col-3 controlLabel">
                        <label for="txtCCMDescription">
                            Description
                        </label>
                    </div>
                    <div class="col-9">
            <textarea id="txtCCMDescription"
                      [cols]="30"
                      [rows]="4"
                      maxlength="500"
                      name="description"
                      formControlName="description"
                      pInputTextarea
                      class="p-inputtext">
            </textarea>
                    </div>

                    <div class="col-3 controlLabel">
                        <label for="ddCCMRegionId">
                            RCZ
                        </label>
                    </div>
                    <div class="col-9">
                        <p-dropdown id="ddCCMRegionId"
                                    [style]="{'width':'100%'}"
                                    [options]="regOptions"
                                    [showClear]="true"
                                    formControlName="regionId"
                                    optionLabel="label"
                                    placeholder="Select a Research Commercial Zone">
                        </p-dropdown>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="grid">
                    <div class="col-3 controlLabel">
                        <br/>
                        <label for="txtCCMComment">
                            Comment
                        </label>
                    </div>
                    <div class="col-9">
                        <span>Please enter comments or notes regarding changes.</span>
                        <textarea id="txtCCMComment"
                                  class="p-inputtext"
                                  [cols]="30"
                                  [rows]="5"
                                  name="Comment"
                                  pInputTextarea
                                  formControlName="approvalComment">
              </textarea>
                    </div>

                </div>
            </div>
        </div>

        <p-footer>
            <div class="grid">
                <div class="col-8">
                </div>
                <div class="col-2 d-grid">
                    <button id="btnCCMReset"
                            type="button"
                            class="p-button-warning alignRight"
                            pButton
                            icon="fa fa-close"
                            (click)="reset()"
                            label="Reset">
                    </button>
                </div>
                <div class="col-2 d-grid">
                    <button id="btnCCMSave"
                            type="submit"
                            class="p-button-success alignRight"
                            pButton
                            icon="fa fa-save"
                            (click)="save()"

                            label="Submit">
                    </button>
                    <!--[disabled]="!form.valid"-->
                </div>
            </div>
        </p-footer>
    </p-panel>
</form>
