import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Constants } from './../shared/constants';
import { RequestStatus } from '../shared';
import { SelectItem, Message, ConfirmationService } from 'primeng/api';
import { CommonService } from '.././shared/common.service';
import { EvaluationZoneService } from '.././evaluationzone/evaluationzone.service';
import { CropModel } from './../shared/cropModel';
import { RegionModel } from './../shared/regionModel';
import { Person } from '.././shared/person';
import { EvaluationZoneResultModel } from '.././shared/evaluationZoneResultModel';
import { ApprovalComments } from '.././shared/approvalComments';
import { UserRequestedData } from '.././shared/userRequestedData';
import { NgForm } from '@angular/forms';
import { Region } from './../shared/region';
import { EvaluationZoneType } from './../shared/evaluationZoneType';
import { SeedEvaluationZoneCombo } from './../shared/seedEvaluationZoneCombo';
import { EvaluationZoneForm } from './../shared/evaluationZoneFormControls';
import { AppService } from '../app.service';
import { ActivatedRoute, CanDeactivate } from '@angular/router';
import { ClearClonedData } from '../createprogram/createprogram.component';

@Component({
  selector: 'app-createevaluationzone',
  templateUrl: './createevaluationzone.component.html',
  styleUrls: ['./createevaluationzone.component.scss']
})
export class CreateevaluationzoneComponent implements OnInit, OnChanges, CanDeactivate<ClearClonedData> {
  msgs: Message[] = [];
  public displayEvaluationZoneLead = false;
  public ezLead: SelectItem[] = [];
  public existingPeople: Person[] = [];
  public existingPeopleString = '';
  public evaluationZoneCropName: CropModel[] = [];
  public regionName: RegionModel[] = [];
  public allRegions: RegionModel[] = [];
  public evaluationZoneCodes: SeedEvaluationZoneCombo[] = [];
  public evaluationZoneTypeName: EvaluationZoneType[] = [];
  public evaluationZoneData: EvaluationZoneResultModel = new EvaluationZoneResultModel();
  public parentComponent = 'CreateEvaluationZone';  
  public displayEvaluationZoneTypeDialog: boolean;
  public EvaluationZoneType: string = Constants.LOOKUPTYPEEVALUATIONZONETYPE;
  public createEvaluationZoneFormGroup: FormGroup;
  public comments = '';
  public evaluationZoneForm = new EvaluationZoneForm(
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    new Region(),
    '',
    '',
    [],
    '',
    '',
    [],
    new Person(),
    '',
    new Date(),
    '',
    new Person(),
    new Date(),
    '',
    new Person(),
    new Date(),
    '',
    new Person(),
    new Date(),
    ''
  );

  @Input()
  public selectedTabIndex: number;

  constructor(private _commonService: CommonService, private _evaluationZoneService: EvaluationZoneService,private route: ActivatedRoute,
    private _appService: AppService, private _fb: FormBuilder,
    private _confirmation: ConfirmationService) {
  }

  ngOnInit() {
    this.buildForm();
    this.evaluationZoneData.RequestStatus = RequestStatus.PENDING;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selectedTabIndex === 3) {
      this.bindEvaluationZoneDropdownLists(); 
      this.getEvaluationZoneTypeLookUp(); 

      var typeValue = this.route.snapshot.paramMap.get("type");
      if(typeValue){
        this.setupClone();
      }
    }
  }

  canDeactivate(component: ClearClonedData): boolean {
    localStorage.removeItem("ToBeClonedEZ");
    return true;
  }

  setupClone(): void {
    const dataToBeCloned = localStorage.getItem("ToBeClonedEZ");
    if (dataToBeCloned && dataToBeCloned !== 'undefined') {
      var data = JSON.parse(dataToBeCloned) as EvaluationZoneResultModel;
      this.evaluationZoneData = data;

      this.evaluationZoneData.EvaluationZoneID = "";
      this.evaluationZoneData.CropID = ""; 
      this.evaluationZoneData.CropDescription = "";  
      this.evaluationZoneData.CropName = "";   
      this.evaluationZoneData.PRISM_AOI_ID = "";  
      this.evaluationZoneData.BaseEvaluationZoneID = "";   
      this.evaluationZoneData.RegionID = ""; 
      this.evaluationZoneData.RequestStatus = RequestStatus.PENDING;

      if (this.evaluationZoneData.EvaluationZoneLeads != null) {
        this.evaluationZoneData.EvaluationZoneLeads.forEach(pr => {
          this.ezLead.push({ label: pr.Person.DisplayName, value: pr.Person.DisplayName });
        });
      }
    }
  }

  buildForm(): void {
    this.createEvaluationZoneFormGroup = this._fb.group({
      'EvaluationZoneName': [this.evaluationZoneForm.EvaluationZoneName, Validators.required],
      'EvaluationZoneCode': [this.evaluationZoneForm.EvaluationZoneCode, Validators.required],
      'EvaluationZoneLeadNames': [this.evaluationZoneForm.EvaluationZoneLeadNames],
      'Description': [this.evaluationZoneForm.Description],
      'Color': [this.evaluationZoneForm.Color],
      'CropID': [this.evaluationZoneForm.CropID, Validators.required],
      'RegionID': [this.evaluationZoneForm.RegionID],
      'CropName': [this.evaluationZoneForm.CropName],
      'CropDescription': [this.evaluationZoneForm.CropDescription],
      'EvaluationZoneTypeID': [this.evaluationZoneForm.EvaluationZoneTypeID],
      'EvaluationZoneTypeCode': [this.evaluationZoneForm.EvaluationZoneTypeCode],
      'EvaluationZoneTypeName': [this.evaluationZoneForm.EvaluationZoneTypeName],
      'RequestStatus': [{ value: this.evaluationZoneForm.RequestStatus, disabled: true }],
      'Programs': [{ value: this.evaluationZoneForm.Programs, disabled: true }],
      'PRISM_AOI_ID': [this.evaluationZoneForm.PRISM_AOI_ID],
      'Comment': [this.evaluationZoneForm.ApprovalComments]
    });
  }

  bindEvaluationZoneDropdownLists() {
    this._evaluationZoneService.bindEvaluationZoneDropdownLists().subscribe({
      next: data => {
        if (data.EvaluationZoneCodes && data.EvaluationZoneCodes.length > 0) {
          this.evaluationZoneCodes = [];

          data.EvaluationZoneCodes.forEach((item: { Id: any; Code: string; Name: string; CropID: any; RequestStatus: any; }) => {
            this.evaluationZoneCodes.push({
              Id: item.Id,
              Code: item.Code, Name: item.Name,
              CropID: item.CropID,
              RequestStatus: item.RequestStatus,
              label: item.Code + ' - ' + item.Name,
              value: item.Id
            });
          });
        }
        if (data.Crops && data.Crops.length > 0) {
          this.evaluationZoneCropName = data.Crops;
          this.evaluationZoneCropName.forEach(element => {
            element.value = element.CropID;
            element.label = element.CropDescription;
          });
        }

        if (data.EvaluationZoneTypes && data.EvaluationZoneTypes.length > 0) {
          this.evaluationZoneTypeName = data.EvaluationZoneTypes;
          this.evaluationZoneTypeName.forEach(element => {
            element.value = element.Id;
            element.label = element.Code + ' - ' + element.Name;
          });
        }

        if (data.Regions && data.Regions.length > 0) {
          this.regionName = data.Regions;
          this.regionName.forEach(element => {
            element.value = element.Id;
            element.label = element.Code;
          });
        }
      },
      error: error => {
        if (error === '') {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.DisplayErrorMessage('Error', error);
        }
      }
  });
  } 

  showEvaluationZoneTypeDialog() {
    this.displayEvaluationZoneTypeDialog = true;
  }  

  public getEvaluationZoneTypeLookUp() {
    this._commonService.getLookupTypes(Constants.LOOKUPTYPEEVALUATIONZONETYPE, true)
      .subscribe({
        next: (response) => {
          this.evaluationZoneTypeName = response;

          this.evaluationZoneTypeName.forEach(element => {
            element.value = element.Id;
            element.label = element.Name;
          });
        },
        error: (error) => {
        }
      });
  }



  getRegionsByCropID() {
    this.allRegions = [];
    const cropID = this.evaluationZoneData.CropID;

    if (this.evaluationZoneData.RegionID) {
      this.evaluationZoneData.RegionID = '';
    }
    this.allRegions = this.regionName.filter(s => s.CropID === cropID);
  }

  ShowPeoplePicker() {
    this.existingPeople = [];
    if (this.evaluationZoneData.EvaluationZoneLeads) {
      this.evaluationZoneData.EvaluationZoneLeads.forEach(lead => {
        this.existingPeople.push(lead.Person);
      });
    }

    const obj = new UserRequestedData();
    obj.ParentComponent = this.parentComponent;
    obj.person = this.existingPeople;
    obj.singleLead = false;
    this.existingPeopleString = JSON.stringify(obj);
    this.displayEvaluationZoneLead = true;
  }

  onaddUserDataClick(data: UserRequestedData): void {
    if (!(data == null && data === undefined) && data.ParentComponent === this.parentComponent) {
      this.displayEvaluationZoneLead = false;
      let evaluationZoneLeadNames = '';

      this.evaluationZoneData.EvaluationZoneLeads = [];
      this.evaluationZoneData.EvaluationZoneLeadNames = '';
      this.existingPeople = [];
      this.ezLead = [];

      data.person.forEach(pr => {
        this.existingPeople.push(pr);

        if (!this.evaluationZoneData.EvaluationZoneLeads.find(s => s.Person.LoginName === pr.LoginName)) {
          this.evaluationZoneData.EvaluationZoneLeads.push({
            ID: '',
            PersonID: pr.PersonID, Person: pr, EvaluationZone: null, EvaluationZoneID: this.evaluationZoneData.EvaluationZoneID
          });
          this.ezLead.push({ label: pr.DisplayName, value: pr.DisplayName });
        }

        evaluationZoneLeadNames = evaluationZoneLeadNames + pr.DisplayName + '; ';
      });

      this.evaluationZoneData.EvaluationZoneLeadNames = evaluationZoneLeadNames;
      this.evaluationZoneData.EvaluationZoneLeadNames = this.evaluationZoneData.EvaluationZoneLeadNames.trim();

      if (this.evaluationZoneData.EvaluationZoneLeadNames.lastIndexOf(';')
        === this.evaluationZoneData.EvaluationZoneLeadNames.length - 1) {
        this.evaluationZoneData.EvaluationZoneLeadNames = this.evaluationZoneData.EvaluationZoneLeadNames
          .substring(0, this.evaluationZoneData.EvaluationZoneLeadNames.lastIndexOf(';'));
      }
    }
  }

  saveEvaluationZone(frm:any) {
    const crop = this.evaluationZoneCropName.find(s => s.value === this.evaluationZoneData.CropID);
    if (crop) {
      this.evaluationZoneData.CropDescription = crop.CropDescription;
      this.evaluationZoneData.CropName = crop.CropName;
      this.evaluationZoneData.CropID = crop.CropID;
    }

    const evaluationZoneType = this.evaluationZoneTypeName.find(s => s.value === this.evaluationZoneData.EvaluationZoneTypeID);
    if (evaluationZoneType) {
      this.evaluationZoneData.EvaluationZoneTypeCode = evaluationZoneType.Code;
      this.evaluationZoneData.EvaluationZoneTypeName = evaluationZoneType.Name;
      this.evaluationZoneData.EvaluationZoneTypeID = evaluationZoneType.Id;
    }

    const region = this.regionName.find(s => s.value === this.evaluationZoneData.RegionID);
    if (region) {
      this.evaluationZoneData.Region = region;
    }

    if (this.validateForm(frm)) {
      if (this.comments
        && this.comments.toString().trim() !== '') {
        const comment = new ApprovalComments();
        comment.Comment = this.comments.toString();
        this.evaluationZoneData.ApprovalComments = [];
        this.evaluationZoneData.ApprovalComments.push(comment);
      }

      this._evaluationZoneService.saveEvaluationZone(JSON.stringify(this.evaluationZoneData))
        .subscribe({
          next: Result => { 
            if (Result.Status === 'SUCCESS') {
            this.evaluationZoneCodes.push({
              Id: '',
              Code: this.evaluationZoneData.EvaluationZoneCode,
              Name: this.evaluationZoneData.EvaluationZoneName,
              CropID: this.evaluationZoneData.CropID,
              RequestStatus: this.evaluationZoneData.RequestStatus,
              label: this.evaluationZoneData.EvaluationZoneCode + ' - ' + this.evaluationZoneData.EvaluationZoneName,
              value: ''
            });

            this._appService.sendPendingCount(1);

            this.DisplaySuccessMessage('Success', Result.Message);
            this.reset();

            const dataToBeCloned = localStorage.getItem("ToBeClonedEZ");
            if (dataToBeCloned && dataToBeCloned !== 'undefined') {
              this._confirmation.confirm({
                message: "Would you like to clone again?",
                accept: () => {
                  this.setupClone();
                },
                reject: () => {
                  localStorage.removeItem("ToBeClonedEZ");
                }
              });
            }
          }
          else {
            this.DisplayErrorMessage('Error', Result.Message);
            localStorage.removeItem("ToBeClonedEZ");
          } 
        },
          error: error => {
            if (error === '') {
              this.DisplayErrorMessage('Error', Constants.NOSERVICE);
            } else {
              this.DisplayErrorMessage('Error', error);
            }

            localStorage.removeItem("ToBeClonedEZ");
          }
    });
    }
  }

  validateForm(frm: NgForm) {
    if (!this.evaluationZoneData.EvaluationZoneName || this.evaluationZoneData.EvaluationZoneName.trim() === '') {
      this.DisplayWarningMessage('Warning', 'Evaluation Zone Name is required...');
      return false;
    }

    if ((!this.evaluationZoneData.EvaluationZoneCode
      || (this.evaluationZoneData.EvaluationZoneCode
        && this.evaluationZoneData.EvaluationZoneCode.trim() === ''))) {
      this.DisplayWarningMessage('Warning', 'Evaluation Zone Code is required...');
      return false;
    }

    if ((this.evaluationZoneData.EvaluationZoneCode
      && (this.evaluationZoneData.EvaluationZoneCode.trim().length < 2
        || this.evaluationZoneData.EvaluationZoneCode.trim().length > 4))) {
      this.DisplayWarningMessage('Warning', 'Evaluation Zone Code should be between 2 and 4 characters long...');
      return false;
    }

    if (!this.evaluationZoneData.CropName || this.evaluationZoneData.CropName === '') {
      this.DisplayWarningMessage('Warning', 'Crop is required...');
      return false;
    }

    const ezCode = this.evaluationZoneCodes.find(s => s.Code.toString().toLowerCase()
      === this.evaluationZoneData.EvaluationZoneCode.toString().toLowerCase()
      && s.CropID === this.evaluationZoneData.CropID && s.RequestStatus !== RequestStatus.RETIRED);

    if (ezCode) {
      this.DisplayWarningMessage
        ('Warning', 'This Evaluation Zone Code and Crop combo already exists. Please enter a new Code or Crop...');
      return false;
    }

    if (this.evaluationZoneData.Color
      && (this.evaluationZoneData.Color.toString().indexOf('-') >= 0
        || this.evaluationZoneData.Color.toString().indexOf('.') >= 0)) {
      this.DisplayWarningMessage('Warning', 'Decimal or negative value is not allowed for Color...');
      return false;
    }

    if (this.evaluationZoneData.Color
      && this.evaluationZoneData.Color.toString().trim().length > 9) {
      this.DisplayWarningMessage('Warning', 'Allowed Max length is 9 characters for Color...');
      return false;
    }
    return true;
  }

  clearRegion() {
    this.evaluationZoneData.RegionID = '';
  }

  clearCrop() {
    this.evaluationZoneData.CropID = '';
    this.evaluationZoneData.RegionID = '';
  }

  clearEZT() {
    this.evaluationZoneData.EvaluationZoneTypeID = '';
  }

  public DisplayErrorMessage(title: string, message: string) {
    this._commonService.DisplayErrorMessage(title, message);
  }

  public DisplayWarningMessage(title: string, message: string) {
    this._commonService.DisplayWarningMessage(title, message);
  }

  public DisplayInfoMessage(title: string, message: string) {
    this._commonService.DisplayInfoMessage(title, message);
  }

  public DisplaySuccessMessage(title: string, message: string) {
    this._commonService.DisplaySuccessMessage(title, message);
  }

  reset() {
    this.evaluationZoneData = new EvaluationZoneResultModel();
    this.evaluationZoneData.RequestStatus = RequestStatus.PENDING;
    this.ezLead = [];
    this.existingPeople = [];
    this.comments = '';
  }
}