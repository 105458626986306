import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { Message, SelectItem, ConfirmationService } from "primeng/api";
import { Dropdown } from "primeng/dropdown";
import { AppService } from "../app.service";
import { ProgramService } from "../program/program.service";
import {
  ApprovalComments, BreedingZoneCombo, Constants, CropModel,
  Person, Program, ProgramForm, ProgramPurpose, ProgramPurposeModel, ProgramResultModel,
  ProgramType, ProgramTypeModel, RequestStatus, SeedEvaluationZoneCombo, SeedEvaluationZoneComboModel,
  SeedStorageLocationCombo, SeedStorageLocationComboModel, Station, StationResultModel
} from "../shared";
import { CommonService } from "../shared/common.service";
import { UserRequestedData } from "../shared/userRequestedData";
import { LookupType } from "../shared";
import { ActivatedRoute, CanDeactivate } from "@angular/router";

export interface ClearClonedData {
}

@Component({
  selector: 'app-createprogram',
  templateUrl: './createprogram.component.html',
  styleUrls: ['./createprogram.component.css']
})

export class CreateprogramComponent implements OnInit, OnChanges, CanDeactivate<ClearClonedData> {
  msgs: Message[] = [];
  public displayStationLead = false;
  public programLead: SelectItem[] = [];
  public existingPeople: Person[] = [];
  public existingPeopleString = '';
  public programStationName: Station[] = [];
  public shipToStationName: Station[] = [];
  public allStationName: Station[] = [];
  public allShipToStationName: Station[] = [];
  public programCodes: Program[] = [];
  public programCropName: CropModel[] = [];
  public programTypeName: ProgramType[] = [];
  responsiblesubfunctions: LookupType[];
  public seedStorageLocationName: SeedStorageLocationCombo[] = [];
  public allSeedStorageLocationName: SeedStorageLocationCombo[] = [];
  public seedEvaluationZoneName: SeedEvaluationZoneCombo[] = [];
  public evalutionZones: SeedEvaluationZoneCombo[] = [];
  public seedBreedingZoneName: BreedingZoneCombo[] = [];
  public breedingZones: BreedingZoneCombo[] = [];
  public programPurposeName: ProgramPurpose[] = [];
  public displayResponsibleSubfunctionDialog: boolean;
  public PrimaryResponsibleSubFunction: string = Constants.LOOKUPTYPERESPONSIBLESUBFUNCTION;
  public programData: ProgramResultModel = new ProgramResultModel();
  public displayProgramTypeDialog: boolean;
  public ProgramType: string = Constants.LOOKUPTYPEPROGRAMTYPE;
  public parentComponent = 'CreateProgram';
  public comments = '';
  public selectedBZ: string[] = [];
  public programFormGroup: FormGroup;
  public programForm = new ProgramForm(
    '',
    '',
    '',
    '',
    0,
    '',
    '',
    '',
    '',
    new ProgramTypeModel(),
    '',
    [],
    '',
    new StationResultModel(),
    0,
    0,
    '',
    '',
    new SeedEvaluationZoneComboModel(),
    '',
    '',
    '',
    new StationResultModel(),
    '',
    new SeedStorageLocationComboModel(),
    '',
    new ProgramPurposeModel(),
    '',
    [],
    new Person(),
    '',
    new Date(),
    '',
    new Person(),
    new Date(),
    '',
    new Person(),
    new Date(),
    '',
    new Person(),
    new Date(),
    '',
    [],
    new LookupType(),
    ''
  );

  @Input()
  public selectedTabIndex: number;
  stationAllData: StationResultModel[];
  filteredData: StationResultModel[];

  constructor(private _commonService: CommonService, private _programService: ProgramService,
    private _appService: AppService, private _fb: FormBuilder,
    private route: ActivatedRoute, private _confirmation: ConfirmationService) {
  }

  ngOnInit() {
    this.buildForm();
    this.programData.RequestStatus = RequestStatus.PENDING;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selectedTabIndex === 0) {
      this.bindProgramDropdownLists();
      this.getResponsibleSubfunctionLookUp();

      var typeValue = this.route.snapshot.paramMap.get("type");
      if(typeValue) {
          this.setupClone();
      }
    }
  }

  canDeactivate(component: ClearClonedData): boolean {
    localStorage.removeItem("ToBeClonedProgram");
    return true;
  }

  setupClone(): void {
    const dataToBeCloned = localStorage.getItem("ToBeClonedProgram");
    if (dataToBeCloned && dataToBeCloned !== 'undefined') {
      var data = JSON.parse(dataToBeCloned) as ProgramResultModel;
      this.programData = data;

      this.programData.ProgramID = "";
      this.programData.CropID = "";
      this.programData.CropDescription = "";
      this.programData.CropName = "";
      this.programData.Station = "";
      this.programData.StationID = "";
      this.programData.ShipToStation = "";
      this.programData.ShipToStationID = "";
      this.programData.SeedStorageLocationID = "";
      this.programData.EvaluationZoneID = "";
      this.programData.LEID = "";
      this.programData.BaseProgramID = "";
      this.programData.RequestStatus = RequestStatus.PENDING;

      if (this.programData.ProgramPrincipalInvestigators != null) {
        this.programData.ProgramPrincipalInvestigators.forEach(pr => {
          this.programLead.push({ label: pr.Person.DisplayName, value: pr.Person.DisplayName });
        });
      }
    }
  }

  buildForm(): void {
    this.programFormGroup = this._fb.group({
      'ProgramName': [this.programForm.ProgramName, Validators.required],
      'ProgramCode': [this.programForm.ProgramCode, Validators.required],
      'ProgramPrincipalInvestigatorNames': [this.programForm.ProgramPrincipalInvestigatorNames],
      'Description': [this.programForm.Description],
      'ProgramTypeID': [this.programForm.ProgramTypeID],
      'ProgramSize': [this.programForm.ProgramSize],
      'CropID': [this.programForm.CropID, Validators.required],
      'StationID': [this.programForm.StationID, Validators.required],
      'ShipToStationID': [this.programForm.ShipToStationID],
      'SeedStorageLocationID': [this.programForm.SeedStorageLocationID],
      'EvaluationZoneID': [this.programForm.EvaluationZoneID],
      'Region': [{ value: this.programForm.Region, disabled: true }],
      'ProgramPurposeID': [this.programForm.ProgramPurposeID],
      'CRMLower': [this.programForm.CRMLower],
      'CRMUpper': [this.programForm.CRMUpper],
      'RequestStatus': [{ value: this.programForm.RequestStatus, disabled: true }],
      'LEID': [this.programForm.LEID],
      'Comment': [this.programForm.ApprovalComments],
      'BreedingZoneID': [this.selectedBZ],
      'PrimaryResponsibleSubFunctionId': [this.programForm.PrimaryResponsibleSubFunctionId]
    });
  }

  public getResponsibleSubfunctionLookUp() {
    this._commonService.getLookupTypes(Constants.LOOKUPTYPERESPONSIBLESUBFUNCTION, true)
      .subscribe({
        next: (response) => {
          this.responsiblesubfunctions = response;

          this.responsiblesubfunctions.forEach(element => {
            element.value = element.Id;
            element.label = element.Name;
          });
        },
        error: (error) => {
        }
      });
  }

  showResponsibleSubfunctionDialog() {
    this.displayResponsibleSubfunctionDialog = true;
  }

  showProgramTypeDialog() {
    this.displayProgramTypeDialog = true;
  }

  public getProgramTypeLookUp() {
    this._commonService.getLookupTypes(Constants.LOOKUPTYPEPROGRAMTYPE, true)
      .subscribe({
        next: (response) => {
          this.programTypeName = response;

          this.programTypeName.forEach(element => {
            element.value = element.Id;
            element.label = element.Name;
          });
        },
        error: (error) => {
        }
      });
  }

  bindProgramDropdownLists() {
    this._programService.bindProgramDropdownLists().subscribe({
      next: data => {
        if (data.ProgramCodes && data.ProgramCodes.length > 0) {
          this.programCodes = data.ProgramCodes;
        }

        if (data.Crops && data.Crops.length > 0) {
          this.programCropName = data.Crops;
          this.programCropName.forEach(element => {
            element.value = element.CropID;
            element.label = element.CropDescription;
          });
        }

        if (data.ProgramTypes && data.ProgramTypes.length > 0) {
          this.programTypeName = data.ProgramTypes;
          this.programTypeName.forEach(element => {
            element.value = element.Id;
            element.label = element.Name;
          });
        }

        if (data.ProgramPurposes && data.ProgramPurposes.length > 0) {
          this.programPurposeName = data.ProgramPurposes;
          this.programPurposeName.forEach(element => {
            element.value = element.Id;
            element.label = element.Code + ' - ' + element.Name;
          });
        }

        if (data.Stations && data.Stations.length > 0) {
          this.programStationName = data.Stations;
          this.programStationName.forEach(element => {
            element.value = element.StationID;
            element.label = element.StationCode + ' - ' + element.StationName;
          });

          this.shipToStationName = data.Stations;
          this.shipToStationName.forEach(element => {
            element.value = element.StationID;
            element.label = element.StationCode + ' - ' + element.StationName;
          });
        }

        if (data.SeedStorageLocations && data.SeedStorageLocations.length > 0) {
          this.seedStorageLocationName = data.SeedStorageLocations;
          this.seedStorageLocationName.forEach(element => {
            element.value = element.Id;
            element.label = element.StationName + ' - ' + element.Name;
          });
        }

        if (data.EvalutionZones && data.EvalutionZones.length > 0) {
          this.seedEvaluationZoneName = data.EvalutionZones;
          this.seedEvaluationZoneName.forEach(element => {
            element.value = element.Id;
            element.label = element.Code + ' - ' + element.Name;
          });
        }

        if (data.BreedingZoneCodes && data.BreedingZoneCodes.length > 0) {
          this.seedBreedingZoneName = data.BreedingZoneCodes;
          this.seedBreedingZoneName.forEach(element => {
            element.value = element.Id;
            element.label = element.Code + '-' + element.Name;
          });
        }
      },
      error: error => {
        if (error === '') {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.DisplayErrorMessage('Error', error);
        }
      }
    });
  }

  ShowPeoplePicker() {
    this.existingPeople = [];
    if (this.programData.ProgramPrincipalInvestigators) {
      this.programData.ProgramPrincipalInvestigators.forEach(lead => {
        this.existingPeople.push(lead.Person);
      });
    }

    const obj = new UserRequestedData();
    obj.ParentComponent = this.parentComponent;
    obj.person = this.existingPeople;
    obj.singleLead = false;

    this.existingPeopleString = JSON.stringify(obj);
    this.displayStationLead = true;
  }

  getStationsByCropID(dropdown2?: Dropdown, dropdown3?: Dropdown, dropdown4?: Dropdown) {
    this.allStationName = [];
    this.evalutionZones = [];
    this.breedingZones = [];
    this.allShipToStationName = [];
    const cropID = this.programData.CropID;

    if (this.programData.StationID) {
      this.programData.StationID = '';
      if (dropdown2 !== undefined && dropdown2.filterValue) {
        dropdown2.filterValue = '';
      }
    }

    if (this.programData.ShipToStationID) {
      this.programData.ShipToStationID = '';
      if (dropdown3 !== undefined && dropdown3.filterValue) {
        dropdown3.filterValue = '';
      }
    }

    if (this.programData.EvaluationZoneID) {
      this.programData.EvaluationZoneID = '';
      if (dropdown4 !== undefined && dropdown4.filterValue) {
        dropdown4.filterValue = '';
      }
    }

    if (this.programData.SeedStorageLocationID) {
      this.programData.SeedStorageLocationID = '';
    }

    this.evalutionZones = this.seedEvaluationZoneName.filter(s => s.CropID === cropID);

    this.allStationName = this.programStationName.filter(s => s.StationCrops.find(element => element.CropID
      === cropID));

    this.allShipToStationName = this.shipToStationName.filter(s => s.StationCrops.find(element => element.CropID
      === cropID));

    this.breedingZones = this.seedBreedingZoneName.filter(s => s.CropID === cropID);
  }

  getSeedStorageLocationByCropID(dropdown5?: Dropdown) {
    const cropID = this.programData.CropID;
    const stationID = this.programData.StationID;
    const shipToStationID = this.programData.ShipToStationID;
    this.allSeedStorageLocationName = [];
    this.programLead = [];

    if (dropdown5 !== undefined && dropdown5.filterValue) {
      dropdown5.filterValue = '';
    }
    this.allSeedStorageLocationName = this.seedStorageLocationName.filter(s => s.CropID
      === cropID && (s.StationId === stationID || s.StationId === shipToStationID));
  }

  onaddUserDataClick(data: UserRequestedData): void {
    if (!(data == null && data === undefined) && data.ParentComponent === this.parentComponent) {
      this.displayStationLead = false;
      let PrincipalNames = '';

      this.programData.ProgramPrincipalInvestigators = [];
      this.programData.ProgramPrincipalInvestigatorNames = '';
      this.existingPeople = [];
      this.programLead = [];

      data.person.forEach(pr => {
        this.existingPeople.push(pr);

        if (!this.programData.ProgramPrincipalInvestigators.find(s => s.Person.LoginName === pr.LoginName)) {
          this.programData.ProgramPrincipalInvestigators.push({
            ID: '',
            PersonID: pr.PersonID, Person: pr, Program: null, ProgramID: this.programData.ProgramID
          });
          this.programLead.push({ label: pr.DisplayName, value: pr.DisplayName });
        }

        PrincipalNames = PrincipalNames + pr.DisplayName + '; ';
      });

      this.programData.ProgramPrincipalInvestigatorNames = PrincipalNames;
      this.programData.ProgramPrincipalInvestigatorNames = this.programData.ProgramPrincipalInvestigatorNames.trim();

      if (this.programData.ProgramPrincipalInvestigatorNames.lastIndexOf(';')
        === this.programData.ProgramPrincipalInvestigatorNames.length - 1) {
        this.programData.ProgramPrincipalInvestigatorNames = this.programData.ProgramPrincipalInvestigatorNames
          .substring(0, this.programData.ProgramPrincipalInvestigatorNames.lastIndexOf(';'));
      }
    }
  }

  clearPT() {
    this.programData.ProgramTypeID = '';
  }

  clearCrop(dropdown: Dropdown, dropdown2: Dropdown, dropdown3: Dropdown, dropdown4: Dropdown, dropdown5: Dropdown) {
    if (dropdown.filterValue) {
      dropdown.filterValue = '';
    }
    if (dropdown2.filterValue) {
      dropdown2.filterValue = '';
    }
    if (dropdown3.filterValue) {
      dropdown3.filterValue = '';
    }
    if (dropdown4.filterValue) {
      dropdown4.filterValue = '';
    }
    if (dropdown5.filterValue) {
      dropdown5.filterValue = '';
    }

    this.programData.CropID = '';
    this.programData.StationID = '';
    this.allStationName = [];
    this.programData.ShipToStationID = '';
    this.allShipToStationName = [];
    this.programData.EvaluationZoneID = '';
    this.evalutionZones = [];
    this.programData.SeedStorageLocationID = '';
    this.allSeedStorageLocationName = [];
  }

  clearStation(dropdown2: Dropdown, dropdown5: Dropdown) {
    if (dropdown2.filterValue) {
      dropdown2.filterValue = '';
    }
    this.programData.StationID = '';
    this.getSeedStorageLocationByCropID(dropdown5);
  }

  clearSTS(dropdown3: Dropdown, dropdown5: Dropdown) {
    if (dropdown3.filterValue) {
      dropdown3.filterValue = '';
    }
    this.programData.ShipToStationID = '';
    this.getSeedStorageLocationByCropID(dropdown5);
  }

  clearEZ(dropdown4: Dropdown) {
    if (dropdown4.filterValue) {
      dropdown4.filterValue = '';
    }
    this.programData.EvaluationZoneID = '';
  }

  clearSSL(dropdown5: Dropdown) {
    if (dropdown5.filterValue) {
      dropdown5.filterValue = '';
    }
    this.programData.SeedStorageLocationID = '';
  }

  clearPP() {
    this.programData.ProgramPurposeID = '';
  }

    saveProgram(frm: any) {
    const crop = this.programCropName.find(s => s.value === this.programData.CropID);
    if (crop === undefined || crop === null) {
      this.programData.CropDescription = '';
      this.programData.CropName = '';
      this.programData.CropID = '';
    } else {
      this.programData.CropDescription = crop.CropDescription;
      this.programData.CropName = crop.CropName;
      this.programData.CropID = crop.CropID;
    }

    if (!this.programData.ProgramCode) {
      this.programData.ProgramCode = '';
    }

    this.programData.EvaluationZone = this.seedEvaluationZoneName.find(s => s.value === this.programData.EvaluationZoneID)!;
    this.programData.ProgramPurpose = this.programPurposeName.find(s => s.value === this.programData.ProgramPurposeID)!;
    this.programData.ProgramType = this.programTypeName.find(s => s.value === this.programData.ProgramTypeID)!;

    this.programData.SeedStorageLocation = this.seedStorageLocationName.find(s => s.value === this.programData.SeedStorageLocationID)!;
    this.programData.Station = this.programStationName.find(s => s.value === this.programData.StationID);
    this.programData.ShipToStation = this.shipToStationName.find(s => s.value === this.programData.ShipToStationID);
    this.programData.ProgramBreedingZoneAssoc = [];

    if (this.seedBreedingZoneName && this.seedBreedingZoneName.toString().trim() !== '') {
      this.selectedBZ.forEach(element => {
        this.programData.ProgramBreedingZoneAssoc.push({
          ProgramID: '',
          BreedingZoneID: element,
          // Program: null,
          // BreedingZone: null
        });
      });
    }
    let bzNames = '';

    if (this.programData.ProgramBreedingZoneAssoc) {
      this.programData.ProgramBreedingZoneAssoc.forEach(s => {
        bzNames = bzNames + this.seedBreedingZoneName.find(p => p.Id === s.BreedingZoneID)!.Code + ', ';
      });

      bzNames = bzNames.trim();

      if (bzNames.lastIndexOf(',') === bzNames.length - 1) {
        bzNames = bzNames.substring(0, bzNames.lastIndexOf(','));
      }
    }

    this.programData.BreedingZones = bzNames;

    if (this.validateForm(frm)) {
      if (this.comments
        && this.comments.toString().trim() !== '') {
        const comment = new ApprovalComments();
        comment.Comment = this.comments.toString();
        this.programData.ApprovalComments = [];
        this.programData.ApprovalComments.push(comment);
      }

      this.programData.ProgramCode = !this.programData.ProgramCode ? '' : this.programData.ProgramCode;
      this._programService.saveProgram(JSON.stringify(this.programData)).subscribe({
        next: (Result) => {
          if (Result.Status === 'SUCCESS') {
            if (this.programData.ProgramCode) {
              this.programCodes.push({
                ProgramID: '',
                ProgramCode: this.programData.ProgramCode,
                ProgramName: this.programData.ProgramName,
                CropID: this.programData.CropID,
                RequestStatus: this.programData.RequestStatus,
                EvaluationZoneID: '',
                label: this.programData.ProgramCode + ' - ' + this.programData.ProgramName,
                value: ''
              });
            }

            this._appService.sendPendingCount(1);

            this.DisplaySuccessMessage('Success', Result.Message);
            this.reset();

            const dataToBeCloned = localStorage.getItem("ToBeClonedProgram");
            if (dataToBeCloned && dataToBeCloned !== 'undefined') {
              this._confirmation.confirm({
                message: "Would you like to clone again?",
                accept: () => {
                  this.setupClone();
                },
                reject: () => {
                  localStorage.removeItem("ToBeClonedProgram");
                }
              });
            }
          }
          else {
            this.DisplayErrorMessage('Error', Result.Message);
            localStorage.removeItem("ToBeClonedProgram");
          }
        },
        error: error => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }

          localStorage.removeItem("ToBeClonedProgram");
        }
      });
    }
  }

  validateForm(frm: NgForm) {
    if (!this.programData.ProgramName || this.programData.ProgramName.trim() === '') {
      this.DisplayWarningMessage('Warning', 'Program Name is required...');
      return false;
    }
    if (!this.programData.ProgramCode || this.programData.ProgramCode.trim() === '') {
      this.DisplayErrorMessage('Error', 'Program Code is required...');
      return false;
    }

    if ((this.programData.ProgramSize || this.programData.ProgramSize === 0)
      && (parseFloat(this.programData.ProgramSize.toString()) < 0 || parseFloat(this.programData.ProgramSize.toString()) > 5)) {
      this.DisplayWarningMessage('Warning', 'Program Size should be between 0 and 5...');
      return false;
    }

    if ((this.programData.ProgramSize || this.programData.ProgramSize === 0)
      && this.programData.ProgramSize.toString().indexOf('.') >= 0
      && (parseFloat(this.programData.ProgramSize.toString()).toString().split('.')[1].length) > 2) {
      this.DisplayWarningMessage('Warning', 'Program Size allowed upto 2 decimal places...');
      return false;
    }

    if ((this.programData.ProgramCode
      && (this.programData.ProgramCode.trim().length < 2 || this.programData.ProgramCode.trim().length > 4))) {
      this.DisplayWarningMessage('Warning', 'Program Code should be between 2 and 4 characters long...');
      return false;
    }

    // if (!this.programData.ProgramPrincipalInvestigatorNames
    //     || this.programData.ProgramPrincipalInvestigatorNames.trim() === '') {
    //     this.DisplayErrorMessage('Error', 'At least one Principal Investigator is required...');
    //     return false;
    // }

    if (!this.programData.CropName || this.programData.CropName === '') {
      this.DisplayWarningMessage('Warning', 'Crop is required...');
      return false;
    }

    if (!this.programData.Station
      || (this.programData.Station && this.programData.Station.StationName === '')) {
      this.DisplayWarningMessage('Warning', 'R&D Center Name is required...');
      return false;
    }

    if (this.programData.ProgramCode && this.programData.ProgramCode.toString().trim() !== '') {
      const pCode = this.programCodes.find(s => s.ProgramCode !== null && s.ProgramCode.toString().toLowerCase()
        === this.programData.ProgramCode.toString().toLowerCase()
        && s.CropID === this.programData.CropID && s.RequestStatus !== RequestStatus.RETIRED);

      if (pCode) {
        this.DisplayWarningMessage('Warning', 'This Program Code and Crop combo already exists. Please enter a new Code or Crop...');
        return false;
      }
    }

    // if (!this.programData.SeedStorageLocation
    //   || (this.programData.SeedStorageLocation && this.programData.SeedStorageLocation.Name === '')) {
    //     this.DisplayErrorMessage('Error', 'Seed Storage Location is required...');
    //     return false;
    // }

    if (this.programData.CRMLower
      && (this.programData.CRMLower.toString().indexOf('.') >= 0)) {
      this.DisplayWarningMessage('Warning', 'Decimal value is not allowed for CRM Lower...');
      return false;
    }

    if (this.programData.CRMLower
      && this.programData.CRMLower === parseInt(this.programData.CRMLower.toString(), 10)
      && (this.programData.CRMLower < -255 || this.programData.CRMLower > 255)) {
      this.DisplayWarningMessage('Warning', 'CRM Lower should be between -255 to 255 digits long...');
      return false;
    }

    if (this.programData.CRMUpper
      && (this.programData.CRMUpper.toString().indexOf('.') >= 0)) {
      this.DisplayWarningMessage('Warning', 'Decimal value is not allowed for CRMUpper...');
      return false;
    }

    if (this.programData.CRMUpper
      && this.programData.CRMUpper === parseInt(this.programData.CRMUpper.toString(), 10)
      && (this.programData.CRMUpper < -255 || this.programData.CRMUpper > 255)) {
      this.DisplayWarningMessage('Warning', 'CRM Upper should be between -255 to 255 digits long...');
      return false;
    }

    if (this.programData.CRMUpper
      && (this.programData.CRMUpper.toString().indexOf('.') >= 0)) {
      this.DisplayWarningMessage('Warning', 'Decimal value is not allowed for CRMUpper...');
      return false;
    }

    if (this.programData.CRMUpper && this.programData.CRMLower
      && (this.programData.CRMUpper === parseInt(this.programData.CRMUpper.toString(), 10)
        && this.programData.CRMLower === parseInt(this.programData.CRMLower.toString(), 10)
        && parseInt(this.programData.CRMUpper.toString(), 10) < parseInt(this.programData.CRMLower.toString(), 10))) {
      this.DisplayWarningMessage('Warning', 'CRM Upper should be greater than CRM Lower...');
      return false;
    }

    return true;
  }

  public DisplayErrorMessage(title: string, message: string) {
    this._commonService.DisplayErrorMessage(title, message);
  }

  public DisplayWarningMessage(title: string, message: string) {
    this._commonService.DisplayWarningMessage(title, message);
  }

  public DisplayInfoMessage(title: string, message: string) {
    this._commonService.DisplayInfoMessage(title, message);
  }

  public DisplaySuccessMessage(title: string, message: string) {
    this._commonService.DisplaySuccessMessage(title, message);
  }

  reset() {
    this.programData = new ProgramResultModel();
    this.programData.RequestStatus = RequestStatus.PENDING;
    this.programData.ProgramPrincipalInvestigators = [];
    this.programLead = [];
    this.comments = '';
    this.existingPeople = [];
    this.selectedBZ = [];
  }
}
